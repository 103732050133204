/*#sme-edu .page-header .page-header-title .title {text-align:left;}*/
/*#sme-edu .list-header {margin:0;border:0;padding-top:40px}*/
/*{padding:0 120px;}*/
/*#sme-edu .edu-banner {position:relative;}*/
/* #sme-edu .page-component .line-component{display: flex;flex-direction: column;justify-content: center;align-items: center;} */
/*#sme-edu .edu-banner .banner-content {position:absolute;left:0;top:0;width:100%;height:400px;padding-top:150px;}*/
/*#sme-edu .edu-banner .banner-title {font-size:50px;font-weight:800;color:#fff;line-height:65px}*/
/*#sme-edu .edu-banner .banner-image {line-height:0;}*/
/*#sme-edu .edu-banner .banner-image img {width:100%;height:400px;object-fit:cover;}*/
/*#sme-edu .edu-card-list {display:flex;flex-wrap:wrap;margin:-20px -10px}*/
/*#sme-edu .edu-card-item {flex:0 0 33.3%;padding:20px 10px;overflow: hidden;}*/
/*#sme-edu .edu-card {}*/
/*#sme-edu .edu-card .card-link {}*/
/*!* #sme-edu .edu-card .card-image {height:173px} *!*/
/*#sme-edu .edu-card .card-image {height:216px}*/
/*#sme-edu .edu-card .card-image img {width:100%;height:100%;object-fit:cover;}*/
/*#sme-edu .edu-card .card-content {position:relative;padding-top:20px}*/

/*#sme-edu .edu-card .card-content .tags{font-size:20px;font-weight:bold;padding-bottom: 10px;}*/
/*#sme-edu .edu-card .card-content .tags .tag{position:relative;line-height:20px;word-break: nowrap;display: inline-block;text-overflow: clip;overflow: hidden;white-space: nowrap; width: 100%;text-overflow:ellipsis;}*/
/*#sme-edu .edu-card .card-content .info{color:#999;font-weight:bold;font-size:14px;}*/
/*#sme-edu .edu-card .card-content .info .text:before {content:"|";margin:0 10px;}*/
/*#sme-edu .edu-card .card-content .title{font-size:14px;padding-bottom: 10px;color: #999}*/
/*#sme-edu .edu-card .card-content .info .text{font-size:14px;padding-right: 10px;}*/
/*#sme-edu .edu-card .card-content .info .time{position:relative;padding-left:15px}*/
/*#sme-edu .edu-card .card-content .info .time:before{content:'';position:absolute;left:0;top:2px;width:9px;height:11px;background: url(../img/edu/play.svg)}*/
/*#sme-edu .edu-card .card-content .actions {position:relative;padding-top:20px;display:flex;}*/
/*#sme-edu .edu-card .card-content .actions .btn {border-radius:2px;width:120px;height:60px;background-color:white;transition: all 0.3s ease;}*/
/*#sme-edu .edu-card .card-content .actions .btn.btn-outline-white .text {color: #015aa8;}*/
/*#sme-edu .edu-card .card-content .actions .btn.btn-outline-white:hover {background-color:#015aa8;}*/
/*#sme-edu .edu-card .card-content .actions .btn:not(:first-child) {margin-left:5px;}*/
/*#sme-edu .edu-card .card-content .actions .btn.btn-primary .text {color:#ff7300;}*/
/*#sme-edu .edu-card .card-content .actions .btn.btn-primary {border-color:#ebebeb;}*/
/*#sme-edu .edu-card .card-content .actions .btn.btn-primary:hover {background-color:#ff7300;}*/
/*#sme-edu .edu-card .card-content .actions .btn:hover .text {color:white}*/

/*#sme-edu .page-buttons {margin-top:60px;}*/

[class*='-component'] {
	padding: 0 60px;
	width: 1700px;
	// width: 1400px;
}

[id^='sme-edu'] {
	padding-bottom: 0;
}
#sme-edu .page-component {
	width: 1700px;
}

[id^='sme-edu'] .page-header .page-header-title .title {
	text-align: left;
}
[id^='sme-edu'] .list-header {
	margin: 0;
	border: 0;
	padding-top: 40px;
}
[id^='sme-edu'] .edu-banner {
	position: relative;
}
[id^='sme-edu'] .edu-banner .banner-content {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 400px;
	display: flex;
}
[id^='sme-edu'] .edu-banner .banner-title {
	font-size: 50px;
	font-weight: 700;
	color: #fff;
	line-height: 80px;
	font-family: var(--font-family-nanumsquare);
	padding-top: 35px;
}
[id^='sme-edu'] .edu-banner .banner-text {
	margin-top: 18px;
	font-size: 30px;
	line-height: 40px;
	color: #fff;
	font-weight: 800;
}
[id^='sme-edu'] .edu-banner .banner-image {
	line-height: 0;
	text-align: center;
}
[id^='sme-edu'] .edu-banner .banner-image img {
	width: 100%;
	height: 400px;
	object-fit: cover;
}

[id^='sme-edu'] .edu-section {
	position: relative;
	padding: 50px 0;
}
[id^='sme-edu'] .edu-section:first-child {
	width: 100vw;
	background-color: #deede2;
}

[id^='sme-edu'] .lecture-list-container {
	position: relative;
	padding-top: 60px;
	padding-left: 120px;
	padding-right: 120px;
	padding-bottom: 60px;
}

[id^='sme-edu'] .lecture-swiper-wrapper {
}
[id^='sme-edu'] .lecture-article {
}
[id^='sme-edu'] .lecture-article .article-header {
	margin-bottom: 20px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
[id^='sme-edu'] .lecture-article .article-header .title {
	font-size: 20px;
	font-weight: 700;
	font-family: var(--font-family-nanumsquare);
}
[id^='sme-edu'] .lecture-article .article-header .subtext {
	color: #999;
}
[id^='sme-edu'] .lecture-article ~ .lecture-article {
	margin-top: 50px;
}
[id^='sme-edu'] .edu-swiper-container {
	overflow: hidden;
	position: relative;
	padding-top: 60px;
	margin-top: -60px;
}
[id^='sme-edu'] .edu-swiper-wrapper {
}
[id^='sme-edu'] .edu-slide-item {
	width: 285px;
	height: 287px;
}
[id^='sme-edu'] .edu-swiper-buttons {
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
}
[id^='sme-edu'] .edu-swiper-buttons .btn {
	width: 40px;
	height: 40px;
	border: 1px solid #ebebeb;
	background-color: #f3f5f7;
	padding: 0;
	margin-left: 5px;
}
[id^='sme-edu'] .edu-swiper-buttons .icon-arrow {
	width: 6px;
	height: 10px;
	background-image: url(../img/edu/icon_swiper_arrow.svg);
}
[id^='sme-edu'] .edu-swiper-buttons .btn-prev1 .icon-arrow {
	transform: rotate(-180deg);
}
[id^='sme-edu'] .edu-swiper-buttons .btn-prev2 .icon-arrow {
	transform: rotate(-180deg);
}
[id^='sme-edu'] .edu-swiper-buttons .btn-prev3 .icon-arrow {
	transform: rotate(-180deg);
}
[id^='sme-edu'] .edu-swiper-buttons .btn-prev4 .icon-arrow {
	transform: rotate(-180deg);
}
[id^='sme-edu'] .edu-swiper-buttons .swiper-button-disabled .icon-arrow {
	opacity: 0.5;
}
[id^='sme-edu'] .edu-box {
}
[id^='sme-edu'] .edu-thumbnail {
	width: 100%;
	height: 162px;
	border: 1px solid #ebebeb;
}
[id^='sme-edu'] .edu-thumbnail img {
	pointer-events: none;
	width: 100%;
	height: 100%;
}
[id^='sme-edu'] .edu-thumbnail .thumbnail-link {
	pointer-events: none;
	width: 100%;
	height: 100%;
}
[id^='sme-edu'] .edu-contents {
	min-height: 1000px;
	line-height: 24px;
}
[id^='sme-edu'] .edu-contents .line-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
[id^='sme-edu'] .edu-contents .title {
	font-weight: bold;
	font-size: 16px;
}

/*!*display:flex;align-items:center;*!*/
/*[id^="sme-edu"] .edu-contents .title:before {content:"";flex-grow:1;margin: 0px 16px;background: rgba(0,0,0,0.35);height: 1px;font-size: 0px;line-height: 0px}*/
/*[id^="sme-edu"] .edu-contents .title::after {content:"";flex-grow:1;margin: 0px 16px;background: rgba(0,0,0,0.35);height: 1px;font-size: 0px;line-height: 0px}*/
[id^='sme-edu'] .edu-contents .line {
	border-radius: 5px;
	width: 500px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-bottom: 8px;
}
[id^='sme-edu'] .edu-contents .line-box {
	width: 900px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 18px;
}
[id^='sme-edu'] .edu-contents .tags {
	font-size: 18px;
	display: inline-block;
	text-overflow: clip;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	text-overflow: ellipsis;
}
[id^='sme-edu'] .edu-contents .description {
	color: #999;
	font-weight: bold;
	font-size: 16px;
}
[id^='sme-edu'] .edu-actions {
	margin-top: 10px;
}
[id^='sme-edu'] .edu-actions .btn {
	width: 95px;
	height: 50px;
}
[id^='sme-edu'] .edu-actions .text {
	font-size: 15px;
}
[id^='sme-edu'] .edu-actions .btn-outline-white .text {
	font-size: 17px;
	color: #015aa8;
}
[id^='sme-edu'] .edu-actions .btn-outline-white:hover {
	background-color: #015aa8;
}
[id^='sme-edu'] .edu-actions .btn-outline-white:hover .text {
	color: white;
}
[id^='sme-edu'] .edu-contents .arrow {
	position: relative;
	margin: auto;
	width: 1440px;
	height: 230px;
}
[id^='sme-edu'] .edu-contents .arrow .left-arrow {
	position: absolute;
	top: 15%;
	left: calc(25%);
	width: 150px;
	transform: rotate(120deg);
}
[id^='sme-edu'] .edu-contents .arrow .right-arrow {
	position: absolute;
	top: 15%;
	left: calc(65%);
	width: 150px;
	transform: rotate(60deg) scaleY(-1);
}

#sme-edu-lectures .edu-banner {
	background-color: #999;
}
#sme-edu-lectures .edu-banner .banner-title {
	display: inline-block;
}
#sme-edu-apply .edu-banner {
	background-color: #77c2c2;
}
#sme-edu-apply .edu-banner .banner-title {
	display: inline-block;
}
#sme-edu-apply .edu-section-text {
	background-color: #fff;
}

.edu-section {
	padding: 80px 0;
}
.edu-section:nth-child(2) {
	padding: 0;
}
/*.edu-section:nth-child(4) {padding-top:0px;padding-bottom:30px;z-index: 3}*/

.edu-section-text {
	text-align: center;
}
.edu-section-text .section-header {
	margin-bottom: 21px;
}
.edu-section-text .section-header .title {
	font-size: 24px;
	font-weight: 700;
	line-height: 42px;
	font-family: var(--font-family-nanumsquare);
}
.edu-section-text .section-header .subtext {
	font-size: 16px;
	line-height: 24px;
	color: #555;
}
.edu-section-text .text-container {
}
.edu-section-text .text-container .text {
	line-height: 30px;
}
.edu-section-text .text-container .left-text {
	line-height: 30px;
	text-indent: 247px;
}
.edu-section-text .text-container .text-link {
	font-weight: bold;
	color: #015aa8;
	text-decoration: underline;
	font-size: 18px;
}
.edu-section-text .text-container .text-link:hover {
	color: #ff7300;
}
.edu-section-text .btn-container {
	margin-top: 32px;
}
.edu-section-text .btn-container .btn {
	width: 320px;
	height: 60px;
}
.edu-section-text .btn-container .text {
	font-weight: bold;
	font-size: 19px;
}

.edu-section-banner {
	margin-bottom: 20px;
}
.edu-section-banner .banner-container {
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
}
.edu-section-banner .banner {
	position: relative;
	width: 632px;
	margin: 0 20px;
}
.edu-section-banner .banner-image {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.edu-section-banner .banner-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.edu-section-banner .banner-content {
	z-index: 1;
	position: relative;
	padding: 30px;
	display: flex;
	flex-direction: column;
	min-height: 250px;
}
.edu-section-banner .banner-content .subtitle {
	font-size: 14px;
	line-height: 15px;
	margin-bottom: 3px;
	font-weight: bold;
	color: #fff;
}
.edu-section-banner .banner-content .title {
	font-size: 25px;
	line-height: 35px;
	font-weight: 700;
	margin-bottom: 16px;
	color: #fff;
	font-family: var(--font-family-nanumsquare);
}
.edu-section-banner .banner-content .description {
	font-size: 16px;
	color: #fff;
	line-height: 18px;
}
.edu-section-banner .banner-content .buttons {
	width: 160px;
	flex: 1 1 auto;
	display: flex;
	align-items: flex-end;
	padding-top: 0px;
}
.edu-section-banner .banner-content .buttons .btn {
	width: 160px;
}
.edu-section-banner .banner-content .buttons .btn:hover .text {
	color: white;
}
.edu-section-banner .banner-content .buttons .btn:hover .subtext {
	color: white;
}
.edu-section-banner .banner-content .buttons .text {
	color: #015aa8;
	font-size: 20px;
	font-weight: bold;
}
.edu-section-banner .banner-content .buttons .subtext {
	text-align: center;
	color: #015aa8;
	font-size: 17px;
	font-weight: bold;
}
.edu-section-banner .banner-content .banner-preview {
	background-color: #999;
}
.edu-section-course .section-header {
	text-align: center;
	margin-bottom: 10px;
}
.edu-section-course .section-header img {
	width: 60px;
}
.edu-section-course .section-header .title {
	font-size: 40px;
	line-height: 30px;
	font-weight: 700;
	margin-bottom: 15px;
	font-family: var(--font-family-nanumsquare);
}
.edu-section-course .section-header .text {
	font-size: 24px;
	line-height: 32px;
}
.edu-section-course .section-header .subtext {
	font-size: 13px;
	line-height: 18px;
	font-weight: bold;
	color: #555;
	margin-top: 4px;
}
.edu-section-course .course {
	width: 500px;
}
.edu-section-course .course-title {
	text-align: center;
	margin-top: 35px;
	margin-bottom: 50px;
}

.edu-section-course .subtitle {
	text-align: left;
	margin-top: 50px;
	margin-bottom: 10px;
}
.edu-section-course .course-thumbnail {
	width: 410px;
	height: 380px;
	border-radius: 2px;
	overflow: hidden;
	isolation: isolate;
}
.edu-section-course .course-thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.edu-section-course .course-content .subtitle {
	text-align: center;
	font-size: 16px;
	line-height: 24px;
	font-weight: bold;
}

.edu-section-course .course-container .course:last-child .course-content .title,
.edu-section-course .course-container .course:last-child .course-content .text {
	border-bottom: 0px solid;
}
.edu-section-course .course-content .subtext {
	text-align: center;
	margin-top: 8px;
	font-size: 13px;
	line-height: 18px;
	font-weight: bold;
	color: #ff7300;
}

.edu-section-login {
	background-color: #f3f5f7;
	text-align: center;
}
.edu-section-login .section-header {
	margin-bottom: 30px;
}
.edu-section-login .section-header .title {
	font-size: 24px;
	font-weight: 700;
	line-height: 42px;
	font-family: var(--font-family-nanumsquare);
}
.edu-section-login .section-header .subtext {
	font-size: 16px;
	line-height: 24px;
	color: #555;
}
.edu-section-login .login-container {
	width: 420px;
	margin: auto;
}
.edu-section-login .login-container .form-row ~ .form-row {
	padding-top: 10px;
}
.edu-section-login .login-container .btn-login {
	margin-top: 20px;
	width: 100%;
	height: 80px;
}
.edu-section-login .login-container .btn-login .text {
	font-weight: 700;
	font-size: 20px;
}

.edu-banner .banner-image.corner-image::before {
	display: block;
	width: 630px;
	height: 330px;
	content: '';
	clear: both;
	position: absolute;
	bottom: -5%;
	right: 0;
	background: url(../img/edu/banner_cont.png) no-repeat bottom right / contain;
	z-index: 2;
}
.edu-contents .edu-inner {
	display: flex;
	max-width: 1280px;
	min-height: 1000px;
	margin: 0 auto;
	flex-direction: column;
	align-items: center;
	z-index: -1;
}
.edu-contents .edu-inner .edu-inner_tit {
	position: relative;
	margin-left: 140px;
	margin-right: 140px;
}
#sme-edu .page-component .line-component {
	padding-left: 0;
	padding-right: 0;
	.title.line {
		color: #2c2c2c;
		padding: 15px;
		font-weight: 900;
	}
}
.edu-contents.influencer .section-container.page-component .text {
	margin-top: 15px;
	font-weight: 600;
}
.edu-contents .section-container.page-component .text > p > span {
	font-size: 24px;
}
.green {
	color: #1c6961;
	font-weight: 900;
}
.green_bold {
	color: #263d2c;
	font-weight: 900;
}
.red {
	color: red;
	font-weight: 600;
}
.edu-section-course .course-title .course-title-line {
	padding: 0 10px;
	font-size: 32px;
	font-weight: 900;
}
.edu-contents .edu-inner .course-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
/*.edu-contents .edu-inner .course-container{display: flex; justify-content: space-evenly;align-items: center; margin-right: -200px; margin-left: -200px;}221228임시주석*/
.edu-section-course .course-content {
	position: relative;
	padding: 30px;
	background-color: #fff;
	border-radius: 20px;
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}
.edu-section-course .course-content .ico_01 {
	width: 90px;
	height: 90px;
	position: absolute;
	top: -12%;
	right: 5%;
	background: url(../img/edu/con_icon_01.png) no-repeat top left / contain;
}
.edu-section-course .course-content .ico_02 {
	width: 90px;
	height: 90px;
	position: absolute;
	top: -12%;
	right: 5%;
	background: url(../img/edu/con_icon_02.png) no-repeat top left / contain;
}
.edu-section-course .course-content .title {
	font-size: 26px;
	line-height: 24px;
	font-weight: 900;
	font-family: var(--font-family-nanumsquare);
}
.edu-section-course .course-content .text {
	margin: 15px 0;
	word-break: keep-all;
}
.edu-section-course .course-content .text > p {
	margin-bottom: 10px;
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	color: #333;
}
.edu-section-course .course-content .text > span {
	font-size: 17px;
	color: #575757;
}
.edu-section-course .course-content .btn_more .text-link {
	display: inline-block;
	padding: 15px 60px 15px 30px;
	background: #f64081 url(../img/edu/ico_more_w.png) no-repeat top 50% right 15%;
	border-radius: 20px;
	font-size: 24px;
	color: #fff;
}
.edu-section.section02 {
	padding: 50px 0;
}
.edu-section.section02 .section-header .title {
	font-size: 28px;
}
.section02 .box_step {
	width: 77%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px 10%;
	margin: 0 auto;
	border: 10px dashed #bfdec7;
	text-align: center;
	font-size: 18px;
}
.box_step .text:last-child p {
	margin-top: 10px;
	margin-right: 25px;
}
.box_step .text p {
	display: inline-block;
	color: #1c6961;
	font-size: 22px;
	font-weight: 600;
}
.box_step .text .text-link {
	font-size: 22px;
	font-weight: 600;
}
.box_step .text p.sub1 {
	margin-top: 10px;
	margin-right: 25px;
}
.box_step .green {
	color: #1c6961;
	text-decoration: underline;
	font-weight: 600;
}
.box_step .btn_step {
	display: inline-block;
	padding: 5px 20px;
	background: #deede2;
	border: 5px dashed #bfdec7;
	border-radius: 20px;
	font-size: 15px;
}
.box_step .btn_step span {
	font-weight: 900;
}
.edu-section.section03 {
	padding: 50px 0;
	background: #f3f3f3;
}
.edu-section.section03 .banner {
	display: flex;
	width: 600px;
	height: 400px;
	justify-content: space-between;
	align-items: center;
	border-radius: 15px;
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}
.edu-section.section03 .banner.left {
	background: #1b5e57;
	border: 10px solid #103c37;
}
.edu-section.section03 .banner.right {
	background: #bfdec7;
	border: 10px solid #7fb18c;
}
.edu-section.section03 .banner-image {
	width: 40%;
	height: inherit;
	position: relative;
}
.edu-section.section03 .banner-image img {
	width: 172px;
	height: 172px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.edu-section.section03 .banner-content {
	width: 60%;
}
.edu-section.section03 span {
	display: inline-block;
}
.edu-section.section03 .font44 {
	font-size: 44px;
	margin-bottom: 10px;
	line-height: 56px;
	font-family: 'TmonMonsori';
}
.edu-section.section03 .banner.right .font44 {
	font-family: 'TmonMonsori';
}
.edu-section.section03 .font30 {
	font-size: 30px;
	font-weight: 900;
	font-family: 'TmonMonsori';
}
.edu-section.section03 .banner-content {
	padding: 0;
}
.edu-section.section03 .banner-content .title {
	font-size: 30px;
	font-weight: 900;
	line-height: 40px;
}
.edu-section.section03 .banner-content .description {
	color: #f6dc8a;
	font-size: 22px;
	line-height: 26px;
}
.edu-section.section03 .banner.right .title {
	color: #1b5e57;
}
.edu-section.section03 .banner.right .description {
	width: 270px;
	height: 40px;
	background: #284340;
	color: #fff;
	font-size: 22px;
	text-align: center;
	line-height: 40px;
}
.edu-section.section03 .btn-banner {
	display: inline-block;
	margin-top: 30px;
	padding: 20px 55px 20px 30px;
	background: #f64081 url(../img/edu/ico_more_w.png) no-repeat top 50% right 10%;
	border-radius: 30px;
	font-size: 24px;
	color: #fff;
	font-weight: 600;
}
.edu-section.section03 .btn-banner2 {
	display: inline-block;
	margin-top: 0px;
	padding: 20px 55px 20px 30px;
	background: #f64081 url(../img/edu/ico_more_w.png) no-repeat top 50% right 10%;
	border-radius: 30px;
	font-size: 24px;
	color: #fff;
	font-weight: 600;
}
