#sme-noticeinfo {
	background-color: #f3f5f7;
}
#sme-noticeinfo .page-body .page-component {
	padding: 0 100px;
}
#sme-noticeinfo-view {
	background-color: #fff;
}

.ie #sme-noticeinfo .post-card-item {
	flex: 0 0 380px;
}

/* 공지사항 수정 */
.layout_pc {
	display: block;
}
.layout_mo {
	display: none;
}
.page-body.bg-white[data-fill='true'] {
	background: #fff !important;
}
.page-body.page-bg {
	background: #fff no-repeat top left / 100% !important;
}
.notice-list-wrapper {
	margin-top: 30px;
	border-top: 1px solid #ebebeb;
}
.box_search {
	display: flex;
	padding: 20px 0;
	margin: 20px 0 25px;
	border: 1px solid #ebebeb;
	background: #f9f9f9;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
}
.box_search .box_calendar {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.box_search .calendar {
	display: inline-block;
	position: relative;
}
.box_search .calendar .mx-icon-calendar {
	display: inline-block;
	width: 40px;
	height: 100%;
	position: absolute;
	right: 1px;
	background: #015aa8;
	border-radius: 5px;
}
.box_search .calendar .mx-icon-calendar > svg {
	width: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	fill: white;
	height: 2em;
}
.box_search .calendar input {
	width: 150px;
	height: auto;
	padding: 10px 15px;
	border: 0;
	box-shadow: 0 0 0 2px #ebebeb inset;
	border-radius: 2px;
}
.box_search .calendar span {
	display: inline-block;
	width: 40px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	background: #015aa8;
	border-radius: 5px;
}
.box_search .calendar img {
	width: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.box_search .filter-selector {
	margin: 0 20px;
}
.box_search .search_box input {
	margin-right: 10px;
	padding: 11px 30px;
	color: #111;
	border: 0;
	box-shadow: 0 0 0 2px #ebebeb inset;
	border-radius: 2px;
}
.box_search .calendar input:focus,
.box_search .search_box input:focus {
	box-shadow: 0 0 0 2px #015aa8 inset;
}
.box_search .search_box .search-button {
	background: #015aa8 url(../img/common/search_w.svg) no-repeat top 40% right 5%;
}
.notice_body-header {
	display: flex;
	margin-bottom: 10px;
	justify-content: space-between;
	align-items: center;
}
.notice_body-header .header_left {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.notice_body-header .header_left .all_page {
	margin-right: 10px;
	padding-left: 15px;
	background: url(../img/notice/ico_list.png) no-repeat top 2px left / 13px;
}
.notice_body-header .header_left .current_page {
	padding-left: 15px;
	background: url(../img/notice/ico_current.png) no-repeat top 2px left / 12px;
}
.notice_body-header .header_left span {
	color: #e24d51;
}
.notice_body-header .header_right .btn_cur {
	margin-right: 10px;
	background: #424242;
}
.notice_body-header .header_right .btn_cur:hover {
	background: #5b5b5b;
}
.notice_body-header .header_right .btn_cur .text {
	color: #fff;
}
.notice_body-list table {
	width: 100%;
	text-align: center;
}
.notice_body-list table tr {
	height: 50px;
	border-bottom: 1px solid #dce2e8;
}
.notice_body-list table th {
	position: relative;
}
.notice_body-list table th::after {
	display: block;
	width: 1px;
	height: 20px;
	position: absolute;
	top: 0;
	right: -2px;
	background: #94a3f9;
	content: '';
	clear: both;
}
.notice_body-list table th:last-child::after {
	display: none;
}
.notice_body-list table td {
	position: relative;
	cursor: pointer;
}
.notice_body-list table td.icon {
	display: table-cell;
}
.notice_body-list table td:hover {
	text-decoration: underline;
}
.notice_body-list table td::after {
	display: block;
	width: 1px;
	height: 20px;
	position: absolute;
	top: 0;
	right: -2px;
	background: #dce2e8;
	content: '';
	clear: both;
}
.notice_body-list table td:last-child::after {
	display: none;
}
.notice_body-list thead tr {
	background: #f3f9ff;
	border-top: 2px solid #94a3f9;
	box-shadow: 0 2px 5px rgb(184 196 251 / 100%);
}
.notice_body-list tbody .icon img {
	width: 25px;
}
.notice_body-list tbody .attach img {
	width: 20px;
}
.blind {
	position: absolute !important;
	top: -9999rem !important;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
}

/* 공지사항 상세 */
.notice-post-view-wrapper {
	width: 100% !important;
}
.board_info {
	width: 100%;
}
.board_info dl {
	display: table;
	width: 100%;
	box-sizing: border-box;
	table-layout: fixed;
}
.board_info dt,
.board_info dd {
	display: table-cell;
	padding: 10px 15px;
	word-wrap: break-word;
}
.board_info dt {
	width: 15%;
	background: #f3f9ff;
	font-weight: 500;
	color: #000;
	border: 1px solid #cfd8dc;
}
.board_info dd {
	width: 20%;
	word-break: break-all;
	border: 1px solid #cfd8dc;
}
.board_info dt:first-child,
.board_info dd:first-child {
	border-left: none;
}
.board_info dt:last-child,
.board_info dd:last-child {
	border-right: none;
}
.board_info .x3 dt {
	width: 10%;
}
.board_info .x3 dd {
	width: 60%;
}
.board_info .x4 dt {
	width: 10.2%;
}
.board_info .x4 dd {
	width: 85%;
}
.att_wap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.att_info span {
	display: inline-block;
	position: relative;
}
.att_icon {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	text-indent: -9999rem;
}
.att_icon.pdf {
	background: url(../img/notice/ico_pdf.png) no-repeat top left / 20px 20px;
}
.att_icon.doc {
	background: url(../img/notice/ico_doc.png) no-repeat top left / 20px 20px;
}
.att_icon.wod {
	background: url(../img/notice/ico_doc.png) no-repeat top left / 20px 20px;
}
.att_icon.docx {
	background: url(../img/notice/ico_doc.png) no-repeat top left / 20px 20px;
}
.att_icon.hwp {
	background: url(../img/notice/ico_hwp.png) no-repeat top left / 20px 20px;
}
.att_icon.jpg {
	background: url(../img/notice/ico_jpg.png) no-repeat top left / 20px 20px;
}
.att_icon.png {
	background: url(../img/notice/ico_png.png) no-repeat top left / 20px 20px;
}
.att_icon.ppt {
	background: url(../img/notice/ico_ppt.png) no-repeat top left / 20px 20px;
}
.att_icon.pptx {
	background: url(../img/notice/ico_ppt.png) no-repeat top left / 20px 20px;
}
.att_icon.xlsx {
	background: url(../img/notice/ico_xlsx.png) no-repeat top left / 20px 20px;
}
.att_icon.xlx {
	background: url(../img/notice/ico_xlsx.png) no-repeat top left / 20px 20px;
}
.att_icon.txt {
	background: url(../img/notice/ico_txt.png) no-repeat top left / 20px 20px;
}

.btn_link,
.btn_down {
	display: inline-block;
	padding: 10px 30px 10px 15px;
	text-align: center;
	border-radius: 4px;
	font-weight: 500;
}
.btn_link {
	background: #fff url(../img/notice/ico_link.png) no-repeat top 7px right 5px;
	border: 2px solid #e0e0e0;
	color: #003a88;
}
.btn_down {
	margin-left: 10px;
	background: #003a88 url(../img/notice/ico_down_white.png) no-repeat top 7px right 5px;
	border: 2px solid #003a88;
	color: #fff;
}
.board_content {
	padding: 60px 50px;
	border-bottom: 1px solid #cfd8dc;
	line-height: 30px;
	font-size: 18px;
	color: #464646;
	word-break: keep-all;
}
