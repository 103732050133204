.guide_wrap {margin: 0 50px;}
.guide_wrap > div {padding: 35px 0;}
.guide_wrap h3 {margin-bottom: 35px;font-family: 'S-CoreDream';}
.guide_wrap h3 span {color: #0f518e;}

.guide_wrap .portal_menu ul li strong {display: block;margin-top: 25px;font-size: 19px;line-height: 30px;color: #0f518e;}
.guide_wrap .portal_menu ul li strong + p {margin-top: 10px;}

.guide_wrap .guide_menu .txt_box {margin-left: 45px;}
.guide_wrap .guide_menu .txt_box h5 + p {margin-left: 15px;}
.guide_wrap .guide_menu .txt_box h5,
.guide_wrap .guide_menu .txt_box p {font-size: 27px;line-height: 40px;}
.guide_wrap .guide_menu .txt_box span {display: block;}

.guide_wrap .guide_menu ul {display: flex;margin-top: 40px;}
.guide_wrap .guide_menu ul li {width: 100%;padding: 0 10px;text-align: center;}
.guide_wrap .guide_menu ul li h5 {padding: 10px 0;color: #fff;background: #104161;border: 2px solid #608096;}
.guide_wrap .guide_menu ul li p {font-size: 15px;line-height: 25px;}

.guide_wrap .guide_menu .list li h5 {background: #0f518e;border-color: #000;font-family: 'S-CoreDream';}
.guide_wrap .guide_menu .list li p {height: 90px;margin-top: 20px;color: #0f518e;font-family: 'S-CoreDream';}
.guide_wrap .guide_menu .list li .education2 {display: none;}

.guide_wrap .inquiry ul {margin-left: 20px;}
.guide_wrap .inquiry ul li + li {margin-top: 30px;}
.guide_wrap .inquiry ul li strong {width: 250px;}
.guide_wrap .inquiry ul li strong,
.guide_wrap .inquiry ul li p {display: inline-block;font-size: 27px;}