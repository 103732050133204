@import "~@/assets/scss/abstracts.scss";
@charset "UTF-8";

@import url('https://unpkg.com/swiper@7.0.5/swiper-bundle.min.css');

// 1. Base
@import '~@/assets/scss/base/variables';
@import '~@/assets/scss/font';
@import '~@/assets/scss/base/reboot';
@import '~@/assets/scss/common/components';
@import '~@/assets/scss/base/utilites';

// 2. Common
@import '~@/assets/scss/common/common';
@import '~@/assets/scss/common/field';
@import '~@/assets/scss/common/form/form-check-radio';
@import '~@/assets/scss/common/content';
@import '~@/assets/scss/common/controls';
@import '~@/assets/scss/common/buttons';
@import '~@/assets/scss/common/dropdown';
@import '~@/assets/scss/common/custom';
@import '~@/assets/scss/common/steps';
@import '~@/assets/scss/common/membership';
@import '~@/assets/scss/common/floating';

// 3. Pages
@import '~@/assets/scss/pages';
