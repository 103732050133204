/* 소담상회 수정 */
#gachi {
	min-width: 1902px;
}
#sme-we.sodam_wrap {
	width: 1902px;
	padding: 40px 0 80px;
}
.sodam_wrap {
	width: 1020px;
	margin: auto;
}
.sodam_wrap section {
	margin-bottom: 70px;
}
.sodam_wrap .subtit {
	display: inline-block;
	margin-bottom: 15px;
	font-size: 24px;
	font-family: 'NanumSquare-otfeb';
	color: #333;
}
.sodam_wrap .dot {
	margin-top: 10px;
	font-family: 'NanumSquare-otfeb';
}
.sodam_wrap p {
	line-height: 25px;
	font-family: 'NanumSquare-otfr';
	color: #575757;
}
.sodam_wrap .apply .asterisk {
	margin-top: 10px;
	color: #777777;
}
/* .sodam_wrap .sodam_title{padding-top: 60px; text-align: center;}
.sodam_wrap .sodam_title .title{margin-bottom: 20px;}
.sodam_wrap .sodam_title .title img{width: 220px;height: 65px;}
.sodam_wrap .sodam_title p{font-size: 18px;} */
.sodam_wrap .business_introduce {
	padding-top: 40px;
}
.sodam_wrap .apply > div {
	margin-bottom: 50px;
}
.sodam_wrap .apply .space_introduce .space_content {
	display: flex;
	margin-bottom: 100px;
}
.sodam_wrap .apply .space_introduce .space_content .left,
.sodam_wrap .space_introduce .space_content .right {
	width: 47%;
}
.sodam_wrap .apply .space_introduce .space_content .left {
	margin-right: 3%;
}
.sodam_wrap .apply .space_introduce .space_content .left .img_list {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.sodam_wrap .apply .space_introduce .space_content .left .img_list li {
	width: 48%;
	height: 153px;
	margin-bottom: 20px;
}
.sodam_wrap .apply .space_introduce .space_content .left .img_list img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
/* .sodam_wrap .apply .space_introduce .space_content .right .box_text{height: 153px;} */
.sodam_wrap .apply .space_introduce .space_content .box_text {
	margin-top: 20px;
}
.sodam_wrap .apply .space_introduce .space_content .box_text h4 {
	color: #0457a5;
	font-size: 22px;
	font-family: 'NanumSquare-otfeb';
}
/* .sodam_wrap .apply .space_introduce .space_content .right .box_text .text{margin-top: 10px;} */
/* .sodam_wrap .apply .space_introduce .space_content .box_text .text p{margin: 10px 0 5px; font-size: 18px;} */
/* .sodam_wrap .apply .space_introduce .space_content .right .box_text .text span{font-size: 14px;} */
.sodam_wrap .apply .space_introduce .space_content .box_text .text span {
	display: block;
	line-height: 28px;
}
.sodam_wrap .apply .space_introduce .space_content .box_text .text span:nth-child(2),
.sodam_wrap .apply .space_introduce .space_content .box_text .text span:nth-child(5) {
	font-family: 'NanumSquare-otfb';
}
.sodam_wrap .apply .space_introduce .space_content .box_text .text {
	margin-top: 25px;
}
.sodam_wrap .apply .space_introduce .space_content .box_text .text .address {
	color: #575757;
	font-family: 'NanumSquare-otfr';
}
.sodam_wrap .apply .step_img {
	margin-bottom: 10px;
	text-align: center;
}
.sodam_wrap .apply .apply_step .link {
	color: #015aa8;
	text-decoration: underline;
}
.sodam_wrap .apply .apply_step .apply_box_step {
	display: flex;
	margin-bottom: 10px;
}
.sodam_wrap .apply .apply_step .apply_box_step li {
	display: flex;
	align-items: center;
}
.sodam_wrap .apply .apply_step .apply_box_step li + li {
	margin-left: 10px;
}
.sodam_wrap .apply .apply_step .apply_box_step li + li:before {
	display: inline-block;
	margin-right: 10px;
	content: '>';
}
.sodam_wrap .apply .apply_step .apply_box_step li p {
	width: 180px;
	padding: 10px 0;
	border-radius: 25px;
	text-align: center;
	background-color: #f3f5f7;
	color: #333;
}
.sodam_wrap .apply .space_background {
	position: absolute;
	width: 1901px;
	height: 610px;
	background-color: #f3f5f7;
	left: -250px;
	top: 165px;
}
.sodam_wrap .thumbnail_wrap {
	margin: 0;
}
.sodam_wrap .thumbnail_wrap .thumbnail_list {
	display: flex;
	justify-content: center;
	gap: 20px;
}
.sodam_wrap .thumbnail_wrap .thumbnail_list li {
	border: 1px solid #ddd;
	border-radius: 15px;
	background-color: #fff;
	text-align: center;
}
.sodam_wrap .thumbnail_wrap .thumbnail_content {
	padding: 20px;
	text-align: left;
}
.sodam_wrap .thumbnail_wrap .thumbnail_store {
	margin: 10px 0;
	position: relative;
	color: #333;
	font-size: 16px;
	font-weight: 600;
}
/* .sodam_wrap .thumbnail_wrap .thumbnail_store::after{display: block;content: "";clear: both; width: 20%;height: 8px; position: absolute;bottom: 0;left: 0;background: #c3cdfb;border-radius: 20px; z-index: -1;} */
.sodam_wrap .thumbnail_wrap .thumbnail_text {
	color: #999;
	font-size: 14px;
	font-family: 'NanumSquare-otfr';
}
.sodam_wrap .thumbnail_wrap .thumbnail_img {
	width: 300px;
	height: 200px;
	overflow: hidden;
}
.sodam_wrap .thumbnail_wrap .thumbnail_img a {
	overflow: hidden;
}
.sodam_wrap .thumbnail_wrap .thumbnail_img img {
	width: 100%;
	height: 100%;
	border-radius: 15px 15px 0 0;
}
.sodam_wrap .thumbnail_wrap .btn_group {
	padding: 0 0 10px 20px;
	text-align-last: left;
}
.sodam_wrap .thumbnail_wrap .btn_group .btn-detail {
	margin-right: 10px;
	padding: 8px 30px;
	border-radius: 10px;
}

/* 이미지용 퍼블 css */
.sodam_wrap .thumbnail_wrap .apply_img {
	height: 400px;
}
.sodam_wrap .thumbnail_wrap .apply_img .thumbnail_img {
	height: 100%;
}
.sodam_wrap .thumbnail_wrap .btn_group img {
	width: 81px;
}
.sodam_wrap .thumbnail_wrap .btn_group a:first-child img {
	margin-right: 10px;
}
.sodam_wrap .thumbnail_wrap .btn_group a:last-child img {
	margin-left: 10px;
}

/* swiper-slide */
.sodam_wrap .swiper-pagination-bullet-active {
	background-color: #fff;
}
.sodam_wrap .swiper-pagination-bullet {
	width: 10px;
	height: 10px;
}
