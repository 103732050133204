#sme-connect {background-color:#f3f5f7;}
#sme-connect .page-header {padding:55px 0 20px;}
#sme-connect .tag-search {width:800px;margin:20px auto 20px}
#sme-connect .tag-search .form-control {font-size:18px;padding-left:65px;background:url(../img/connect/tag_search_icon.svg) no-repeat 20px 50%}
#sme-connect .tag-search .form-control:focus {box-shadow:0 0 0 2px #015aa8 inset}
#sme-connect .tag-area {display:flex;justify-content:center;margin:-5px 0;flex-wrap:wrap}
#sme-connect .tag-area .tag-item {margin:5px;}
#sme-connect .tag-area .text {position:relative;}
#sme-connect .tag-area .text:before {content:'#';font-weight:bold;color:#999;margin-right:4px}
#sme-connect .tag-area .btn-primary .text:before {content:'#';font-weight:bold;color:#fff}
#sme-connect .page-body {padding-top:20px;}
#sme-connect .page-body .connect-wrap {}
#sme-connect .page-body .creator-list {}
#sme-connect .page-body .creator-item {}
#sme-connect .filter-selector {position:relative;margin-bottom:20px; z-index: 999}
#sme-connect .filter-selector .form-select {width:295px}


#sme-connect .community-banner {position:relative;}
#sme-connect .community-banner .banner-content {position:absolute;left:0;top:0;width:100%;height:400px;padding-top:150px;}
#sme-connect .community-banner .banner-title {font-size:35px;font-weight:800;color:#fff;line-height:50px}
#sme-connect .community-banner .banner-image {line-height:0;}
#sme-connect .community-banner .banner-image img {width:100%;height:400px;object-fit:cover;}
#sme-connect .page-component {padding:0 120px}
#sme-connect .community-list-wrapper {}


#sme-connect .creator-card .community-image img {width:100%;height:100%;/*object-fit:cover;*/transition:transform .4s ease}
#sme-connect .community-title {display:flex;justify-content:center;padding:20px 0 10px;margin-bottom: 10px}
#sme-connect .community-title .community-title-desc {width:1160px;padding:10px;line-height:25px;font-weight:700;border-radius:2px;background-color:white;font-size:20px; color:#111;text-align: center;}

#sme-connect .community-title .community-title-desc img {width:70%;margin-top: 10px}
#sme-connect .creator-item {flex:0 0 33%;padding:33px 10px}

#sme-connect .creator-card .creator-title {position:relative; margin-bottom: 10px;}

#sme-connect .creator-card {position:relative;padding:20px;background-color:#fff;border-radius:2px}
#sme-connect .creator-card .community-content {padding-bottom:15px}
#sme-connect .creator-card .community-content .badge {margin-bottom:15px}
#sme-connect .creator-card .community-content .text {}
#sme-connect .creator-card .community-content .title-link {overflow:hidden;text-overflow:ellipsis;line-height:22px;font-size:15pt;white-space:nowrap;}
#sme-connect .creator-card .community-images {overflow:hidden;position:relative;}
#sme-connect .creator-card .community-image-wrapper {}
#sme-connect .creator-card .community-image {overflow:hidden;height:200px;}
#sme-connect .creator-card .community-actions .actions-btn:hover .icon-message {background-image:url(../img/community/message_icon_active.svg)}
#sme-connect .creator-card .community-image:hover img {transform:scale(1.065) rotate(0.01deg)}
#sme-connect .creator-card .community-info {padding:15px 0}
#sme-connect .creator-card .community-info .info-row {display:flex;min-width:0}
#sme-connect .creator-card .community-info .info-item {position:relative;font-size:13px;color:#555;}
#sme-connect .creator-card .community-info .info-item.writer {overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}
#sme-connect .creator-card .community-info .info-item:not(.writer) {flex-shrink:0;}
#sme-connect .creator-card .community-info .info-item:not(:first-child):before {content:'·';display:inline-block;margin-right:2px;margin-left:4px;}
#sme-connect .creator-card .community-actions {position:relative;border-top:1px solid #ebebeb;display:flex;padding-top:20px}
#sme-connect .creator-card .community-actions .actions-btn {display:inline-flex;align-items:center;}
#sme-connect .creator-card .community-actions .actions-btn:not(:first-child) {margin-left:15px}
#sme-connect .creator-card .community-actions .icon-reply {width:20px;height:20px;background-image:url(../img/community/reply_icon.svg);margin-right:10px;}
#sme-connect .creator-card .community-actions .icon-message {width:20px;height:20px;background-image:url(../img/community/message_icon.svg);margin-right:10px;}
#sme-connect .creator-card .community-actions .actions-text {font-size:13px;font-weight:900;color:#015aa8;}
#sme-connect .creator-card .community-actions .actions-btn:hover .actions-text {color:#ff7300}



#sme-connect-sub {}
#sme-connect-sub .connect-sub-header {position:relative;height:550px;padding-top:160px;background-color:#8b8687}
#sme-connect-sub .connect-sub-header .page-component {display:flex;justify-content:center;}
#sme-connect-sub .creator-avatar {position:relative;width:140px;height:140px}
#sme-connect-sub .creator-avatar img {width:100%;height:100%;object-fit:cover;border-radius:70px}
#sme-connect-sub .creator-avatar .icon-authentication {width:32px;height:32px;background-size:32px auto;position:absolute;right:8px;bottom:0}
#sme-connect-sub .creator-content {position:relative;padding-top:13px;padding-left:30px;}
#sme-connect-sub .creator-content *{color:#fff;}
#sme-connect-sub .creator-content .creator {display:flex;align-items:center;height:24px}
#sme-connect-sub .creator-content .creator-name {font-size:20px;margin-right:10px;}
#sme-connect-sub .creator-content .creator-title {font-size:40px;font-weight:bold;padding-top:10px;}
#sme-connect-sub .creator-content .creator-tag {padding-top:10px;font-size:16px;font-weight:bold;}
#sme-connect-sub .creator-content .chnlUrl {height: 12px; width: 244px; display: flex; justify-content: left; align-items: center;} /* 2022-06-13 황인배추가  */
#sme-connect-sub .connect-sub-body {position:relative;width:1200px;padding:50px 150px 0;margin-top:-182px;background-color:#fff;}
#sme-connect-sub .connect-sub-body .sns-buttons {display:flex;justify-content:center;padding-top:80px;}
#sme-connect-sub .connect-sub-body .sns-buttons [class^="btn-"] {position:relative;text-align:center;width:106px}
#sme-connect-sub .connect-sub-body .sns-buttons .btn-insta:before,
#sme-connect-sub .connect-sub-body .sns-buttons .btn-insta:after {content:'';position:absolute;top:50%;transform:translateY(-50%); width:1px;height:43px;background-color:#ebebeb}
#sme-connect-sub .connect-sub-body .sns-buttons .btn-insta:before {left:0}
#sme-connect-sub .connect-sub-body .sns-buttons .btn-insta:after {right:0}
#sme-connect-sub .connect-sub-body .sns-buttons [class^="icon"] {width:24px;height:24px;background-position:50%}
#sme-connect-sub .connect-sub-body .sns-buttons .icon-youtube {background-image:url(../img/connect/icon_youtube.svg)}
#sme-connect-sub .connect-sub-body .sns-buttons .icon-insta {background-image:url(../img/connect/icon_insta.svg)}
#sme-connect-sub .connect-sub-body .sns-buttons .icon-facebook {background-image:url(../img/connect/icon_facebook.svg)}
#sme-connect-sub .connect-sub-body .sns-buttons .icon-facebook {background-image:url(../img/connect/icon_facebook.svg)}



.ie #sme-connect .page-body .connect-wrap {}
.ie #sme-connect .page-body .connect-wrap .creator-item {flex:0 0 285px}
