.page-nav {
	border-bottom: 1px solid #ebebeb;
}
.page-header .page-header-title .title {
	font-size: 40px;
	line-height: 60px;
}
.page-header-title .mypage-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: left;
}
.page-header-title .mypage-header .header-profile {
	position: relative;
	overflow: hidden;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.page-header-title .mypage-header .header-profile img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.mypage-section {
	padding-top: 50px;
}
.mypage-section ~ .mypage-section {
	border-top: 1px solid #ebebeb;
}
.mypage-section .section-header {
	text-align: center;
	margin-bottom: 50px;
}
.mypage-section .section-title {
	margin-bottom: 20px;
	font-size: 30px;
	font-weight: 900;
}
.mypage-section .section-subtext {
	color: #555;
}

#sme-mypage .mypage-section {
	width: 560px;
	margin: auto;
}
#sme-mypage .mypage-section ~ .mypage-section {
	margin-top: 50px;
}
#sme-mypage .mypage-section .form-row {
	position: relative;
}
#sme-mypage .mypage-section .form-cellth {
	position: absolute;
	right: 100%;
	width: 100px;
	font-weight: bold;
}
#sme-mypage .mypage-section .form-buttons {
	text-align: center;
}
#sme-mypage .mypage-section .btn {
	margin: 50px auto 0;
	width: 270px;
}

.mypage-dm-list {
	position: relative;
	padding-top: 0px;
}
.mypage-dm-item {
	padding: 20px 0;
}
.mypage-dm-item ~ .dm-item {
	border-top: 1px solid #ebebeb;
}
.mypage-dm-card {
	display: flex;
	align-items: center;
	min-width: 0;
}
.mypage-dm-card .card-image {
	position: relative;
	flex: 0 0 100px;
	overflow: hidden;
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	.image-link {
		display: flex;
		position: relative;
		width: 100px;
		height: 100px;
		align-items: center;
		justify-content: center;
	}
	img {
		position: absolute;
		width: 100px;
		height: 100px;
		object-fit: cover;
	}
}

.mypage-dm-card .card-content {
	position: relative;
	padding-left: 20px;
	flex: auto;
}
.mypage-dm-card .card-content .sender-link {
	display: inline-block;
}
.mypage-dm-card .card-content .sender-link .text:not(.sender) {
	font-weight: bold;
	color: #999;
}
.mypage-dm-card .card-content .sender-link .text.sender {
	display: block;
	margin-bottom: 13px;
	font-size: 25px;
	line-height: 32px;
	font-weight: bold;
}
.mypage-dm-card .card-content .sender-link .text-truncate {
	max-width: 1000px;
	display: inline-block;
}
.mypage-dm-card .card-content .dm-content {
	font-weight: bold;
	color: #999;
}
.mypage-dm-card .card-content .dm-date {
	position: absolute;
	right: 0;
	bottom: 0;
	font-weight: bold;
	color: #999;
}

.show-connect-btn {
	margin-top: 50px;
	text-align: center;
}
.connect-p {
	font-weight: bold;
	line-height: 25px;
	margin-bottom: 20px;
	color: #555;
}

.mypage-studio-list {
	position: relative;
	padding-top: 30px;
}
.mypage-studio-item {
	padding: 20px 0;
}
.mypage-studio-item ~ .mypage-studio-item {
	border-top: 1px solid #ebebeb;
}
.mypage-studio-card {
	position: relative;
	display: flex;
	align-items: center;
	min-width: 0;
}
.mypage-studio-card .card-image {
	position: relative;
	flex: 0 0 100px;
	overflow: hidden;
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	.image-link {
		display: flex;
		position: relative;
		width: 100px;
		height: 100px;
		align-items: center;
		justify-content: center;
	}
	img {
		position: absolute;
		width: 100px;
		height: 100px;
		object-fit: cover;
	}
}

.mypage-studio-card .card-content {
	position: relative;
	padding-left: 20px;
	flex: auto;
}
.mypage-studio-card .card-content .title-link {
	display: inline-block;
}
.mypage-studio-card .card-content .title-link .title {
	display: block;
	margin-bottom: 13px;
	font-size: 25px;
	line-height: 32px;
	font-weight: bold;
}
.mypage-studio-card .card-content .title-link .text-truncate {
	max-width: 1000px;
	display: inline-block;
}
.mypage-studio-card .card-content .studio-text {
	color: #999;
	font-weight: bold;
}
.mypage-studio-card .card-status {
	position: absolute;
	right: 0;
	top: 50%;
	width: 120px;
	transform: translateY(-50%);
	text-align: center;
}
.mypage-studio-card .card-status .status-text {
	font-weight: 900;
}

.post-badge {
	position: absolute;
	right: 20px;
	bottom: 30px;
}
.post-badge .icon-selection {
	width: 60px;
	height: 60px;
	background-image: url(../img/mypage/icon_selection.svg);
}
.post-badge .icon-proceeding {
	width: 60px;
	height: 60px;
	background-image: url(../img/mypage/icon_proceeding.svg);
}
.post-badge .icon-unselection {
	width: 60px;
	height: 60px;
	background-image: url(../img/mypage/icon_unselection.svg);
}

#sme-mypage .post-card-list {
	padding-top: 50px;
}

.board-list-container {
	position: relative;
	padding-top: 50px;
}
.board-list {
}
.board-list-item {
	padding: 20px 0;
}
.board-list-item:not(:first-child) {
	border-top: 1px solid #ebebeb;
}
.board-list-row {
	display: flex;
	justify-content: space-between;
	height: 100px;
	min-width: 0;
}
.board-list-content {
	flex: 0 0 calc(100% - 100px);
}
.board-list-content .badge {
	margin-bottom: 10px;
}
.board-list-content .title-link {
	display: inline-block;
	max-width: 1100px;
	font-size: 25px;
	line-height: 32px;
	font-weight: bold;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.board-list-content .content-date {
	margin-top: 5px;
}
.board-list-content .content-date .text {
	font-size: 1rem;
	line-height: 18px;
	font-weight: bold;
	color: #999;
}
.board-list-actions {
	padding-top: 20px;
	flex: 0 0 100px;
}

.board-view-content {
}
.board-view-content {
	text-align: left;
}
.board-view-content .view-content-image {
}
.board-view-content .view-content-title {
	font-size: 20px;
	line-height: 26px;
	font-weight: 900;
	margin-bottom: 20px;
}
.board-view-content .view-content-description {
	font-size: 1rem;
	line-height: 22px;
	color: #555;
}

#sme-mypage .myapply-list-container {
}
#sme-mypage .myapply-list-container .myapply-header {
	padding: 50px 40px;
}
#sme-mypage .myapply-list-container .myapply-header .title {
	font-size: 30px;
	font-weight: 900;
	line-height: 34px;
}
#sme-mypage .myapply-list-container .myapply-header em {
	font-size: 30px;
	font-weight: 900;
	line-height: 34px;
}
#sme-mypage .myapply-list {
}
#sme-mypage .myapply-item {
}
#sme-mypage .myapply-item ~ .myapply-item {
	margin-top: 10px;
}
#sme-mypage .myapply-card {
	display: flex;
	border: 1px solid #ebebeb;
	justify-content: space-between;
}
#sme-mypage .myapply-content {
	position: relative;
	padding: 50px 40px 40px;
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
#sme-mypage .myapply-content .department {
	font-size: 1rem;
	font-weight: bold;
	color: #999;
	margin-bottom: 10px;
	flex-shrink: 0;
}
#sme-mypage .myapply-content .title {
	flex: 1 1 auto;
	max-width: 320px;
}
#sme-mypage .myapply-content .title-link {
	display: inline-block;
	font-size: 25px;
	font-weight: bold;
	line-height: 32px;
}
#sme-mypage .myapply-content .icon-link-arrow {
	margin-left: 15px;
	width: 18px;
	height: 18px;
	background-image: url(../img/mypage/myapply_linkarrow.svg);
}
#sme-mypage .myapply-content .manager {
	font-size: 1rem;
	font-weight: bold;
	color: #ff7300;
}
#sme-mypage .myapply-proceeding {
	flex: 0 0 660px;
	display: flex;
	background-color: #fafafa;
}
#sme-mypage .proceeding-item {
	position: relative;
	width: 220px;
	height: 220px;
	display: flex;
	flex-direction: column;
	padding: 45px 40px 40px;
	background-color: #fafafa;
}
#sme-mypage .proceeding-item * {
	color: #999;
}
#sme-mypage .proceeding-item.is-proceeding {
	background-color: #015aa8 !important;
}
#sme-mypage .proceeding-item.is-proceeding * {
	color: #fff;
}
#sme-mypage .proceeding-item.is-proceeding:after {
	z-index: 1;
	content: '';
	width: 0;
	position: absolute;
	right: -13px;
	top: 45px;
	height: 0;
	border-left: 13px solid #015aa8;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
}
#sme-mypage .proceeding-item .number {
	display: block;
	font-weight: 900;
	line-height: 22px;
	margin-bottom: 15px;
}
#sme-mypage .proceeding-item .title {
	flex: 1 1 auto;
	font-size: 25px;
	font-weight: 900;
	line-height: 32px;
}
#sme-mypage .proceeding-item .state {
	display: block;
	font-weight: 900;
}
#sme-mypage .proceeding-item:nth-child(2) {
	background-color: #ebebeb;
}
#sme-mypage .proceeding-item.is-proceeding ~ .proceeding-item:nth-of-type(odd) {
	background-color: #ebebeb;
}
#sme-mypage .proceeding-item.is-proceeding ~ .proceeding-item:nth-of-type(even) {
	background-color: #fafafa;
}
#sme-mypage .proceeding-item:last-child.is-proceeding {
	background-color: #ff7300 !important;
}
#sme-mypage .proceeding-item:last-child.is-proceeding:after {
	display: none;
}
