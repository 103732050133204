#sme-campaign .post-card-list.pb-3 {padding-top:0;margin-top:-15px}
#sme-campaign-view .page-component {padding:0 100px}
#sme-campaign-view .post-view-wrapper {width:100%;}
#sme-campaign-view .post-view-body {overflow:visible;position:relative;display:flex;flex-direction:row-reverse;justify-content:space-between;}
#sme-campaign-view .post-editor {width: 800px; margin: 0 auto;}
#sme-campaign-view .post-aside-content .content-row ~ .content-row {margin-top:10px;}
#sme-campaign-view .post-aside-content .content-text {color:#111;}
#sme-campaign-view .post-view-header {border-bottom: 1px solid #ccc; text-align:left;padding-bottom:20px;}
#sme-campaign-view .post-view-header .header-tags {padding-top:5px;}

/* 220603 디자인 변경 */
.badge_wrap .badge-text{display:inline-block;margin: 0 10px 12px 0;padding: 5px 10px; background: #fbe6d9; border: 1px solid #ff7300;border-radius: 20px; font-size:14px;color:#ff7300 !important;font-weight:bold}
#sme-campaign-view .post-aside {z-index: 100; width: 99%; position:fixed;bottom:0px;left: 50%;background: #eff6f9;border: 1px solid #ddd; border-top-left-radius: 20px;border-top-right-radius: 20px; transform: translate(-50%,0);}
#sme-campaign-view .post-aside-wrapper {display: flex; width: 1200px;margin: 0 auto; padding:15px 0;position: relative; justify-content: space-between;}
#sme-campaign-view .post-aside-wrapper .post-aside-title{width: 50%;}
#sme-campaign-view .post-aside-wrapper .post-aside-title .content-text{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;-webkit-box-orient: vertical;}
#sme-campaign-view .post-aside-wrapper .content-title {margin-bottom: 10px;color: #111; font-size: 16px;font-weight:bold}
#sme-campaign-view .post-aside-wrapper .space{width: 25%;}
#sme-campaign-view .post-aside-buttons {width: 20%; position: absolute;bottom: 50%; right: 0;}
#sme-campaign-view .post-aside-buttons::before{display: block;width: 110%;height: 30px; content: ""; position: absolute;top: -10px;left: -12px; background: #eff6f9;border: 1px solid #ddd;border-bottom: none; border-top-left-radius: 20px;border-top-right-radius: 20px;}
#sme-campaign-view .post-aside-buttons a{border-radius: 20px;}


