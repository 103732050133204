.swiper-pagination-bullet {
	background-color: #fff;
	opacity: 0.6;
	text-indent: -9999em;
	overflow: hidden;
	width: 5px;
	height: 5px;
}
.swiper-pagination-bullet-active {
	background-color: #ff7300;
	opacity: 1;
}

#sme-livestudio {
	padding-bottom: 0;
}
#sme-livestudio .page-component,
#sme-livestudio-sub .page-component {
	width: 1400px;
	padding: 0 100px;
}
#sme-livestudio .livestudio-banner {
	position: relative;
}
#sme-livestudio .livestudio-banner .banner-content {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 400px;
	padding-top: 150px;
}
#sme-livestudio .livestudio-banner .banner-title {
	font-size: 50px;
	font-weight: 800;
	color: #ff7300;
	line-height: 65px;
}
#sme-livestudio .livestudio-banner .banner-image {
	line-height: 0;
}
#sme-livestudio .livestudio-banner .banner-image img {
	width: 100%;
	height: 400px;
	object-fit: cover;
}
#sme-livestudio .studio-list-wrapper {
	padding-top: 10px;
}

.livestudio-intro {
	position: relative;
	display: flex;
	padding-top: 40px;
	justify-content: space-between;
	padding-bottom: 40px;
}
.livestudio-intro .studio-introduce {
	flex: 0 0 700px;
}
.livestudio-intro .studio-introduce-title {
	font-size: 24px;
	font-weight: 900;
	margin-bottom: 20px;
}
.livestudio-intro .studio-introduce-text {
	font-size: 20px;
	line-height: 30px;
	color: #555;
}
.livestudio-intro .studio-data {
	flex: 0 0 380px;
}
.livestudio-intro .studio-data-selector {
	margin-bottom: 15px;
}
.livestudio-intro .studio-data-selector .form-select {
	box-shadow: 0 0 0 2px #015aa8 inset;
	color: #015aa8;
}

.livestudio-intro [class^='icon-'] {
	width: 24px;
	min-height: 24px;
	position: absolute;
	left: 0;
	top: -2px;
}
.livestudio-intro .icon-time {
	background-image: url(../img/livestudio/icon_time.svg);
}
.livestudio-intro .icon-parking {
	background-image: url(../img/livestudio/icon_parking.svg);
}
.livestudio-intro .icon-location {
	background-image: url(../img/livestudio/icon_location.svg);
}
.livestudio-intro .icon-phone {
	background-image: url(../img/livestudio/icon_phone.svg);
}
.livestudio-intro .studio-data-description .text {
	font-size: 15px;
	line-height: 16px;
	color: #555;
	word-break: keep-all;
}
.livestudio-intro .description-item {
	position: relative;
	padding-left: 30px;
}
.livestudio-intro .description-item ~ .description-item {
	margin-top: 15px;
}

.studio-list {
	display: flex;
	flex-wrap: wrap;
	margin: -15px;
}
.studio-list-item {
	width: 33.3%;
	padding: 15px;
}
.studio-card {
	position: relative;
	background-color: #fff;
	border-radius: 2px;
	width: 100%;
	cursor: pointer;
}
.studio-card:hover .studio-images img {
	transform: scale(1.065) rotate(0.01deg);
}
.studio-card .studio-images {
	overflow: hidden;
	position: relative;
}
.studio-card .studio-images img {
	transition: all 0.4s ease;
}
.studio-card .studio-image-wrapper {
}
.studio-card .studio-image {
}
.studio-card .studio-image img {
	width: 100%;
	height: 197px;
	object-fit: cover;
}
.studio-card .studio-description {
	position: relative;
	padding: 20px;
}
.studio-card .studio-description .subtitle {
	height: 32px;
	font-weight: bold;
	margin-bottom: 9px;
	color: #999;
}
.studio-card .studio-description .title {
	font-size: 22px;
}

.livestudio-section.section-list {
	background-color: #f3f5f7;
	padding-bottom: 50px;
}
.livestudio-section.section-rating {
	padding-top: 40px;
	padding-bottom: 40px;
}

.livestudio-rating-title {
	font-size: 30px;
	font-weight: 900;
	margin-bottom: 20px;
}

.livestudio-review {
}
.livestudio-review .rating-status {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 40px;
	max-width: 505px;
	margin: auto;
}
.livestudio-review .rating-status .status-list {
	flex: 1 1 auto;
}
.livestudio-review .rating-status .status-list {
	padding-left: 40px;
}
.livestudio-review .rating-status .status-item {
	display: flex;
	align-items: center;
}
.livestudio-review .rating-status .status-item ~ .status-item {
	margin-top: 10px;
}
.livestudio-review .rating-status .status-bar {
	position: relative;
	flex: 1 1 auto;
	height: 4px;
	border-radius: 0.3125rem;
	background-color: #ebebeb;
	max-width: 300px;
	width: 100%;
}
.livestudio-review .rating-status .status-bar .value {
	z-index: 30;
	position: absolute;
	left: 0;
	height: 4px;
	border-radius: 0.3125rem;
	background-color: #ff7300;
}
.livestudio-review .rating-status .status-text {
	flex: 0 0 40px;
	margin-left: 20px;
}
.livestudio-review .rating-status .status-text .text {
	font-size: 13px;
	color: #999;
	font-weight: bold;
}
.livestudio-review .review-item ~ .review-item {
	border-top: 1px solid #ebebeb;
}
.livestudio-review ~ .page-buttons {
	padding-bottom: 120px;
}

.is-selected .tab-count {
	color: #bbb;
}

/* review-list-top */
.review-list-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 15px;
	border-bottom: 1px solid #ebebeb;
}
.review-list-top .top-header {
	display: flex;
	align-items: center;
}
.review-list-top .top-header .title {
	font-size: 1rem;
	color: #111;
	margin-right: 5px;
	font-weight: 800;
}
.review-list-top .top-header .count {
	font-size: 1rem;
	color: #555;
	font-weight: normal;
}
.review-list-top .top-options {
}
.review-list-top .top-options .option-list {
	display: flex;
}
.review-list-top .top-options .option-item {
	margin-left: clamp(7px, 1.4vw, 15px);
}
.review-list-top .top-options .option-link {
}
.review-list-top .top-options .option-text {
	font-size: clamp(13px, 1.4vw, 15px);
	color: #bbb;
	font-weight: 800;
}
.review-list-top .top-options .is-selected {
}
.review-list-top .top-options .is-selected .option-text {
	color: #242424;
}
.review-list-top.is-borderless {
	border-bottom: none;
}
.review-list-top .top-column {
	display: flex;
	align-items: center;
}
.review-list-top .top-date {
}
.review-list-top .top-date .text {
	font-weight: 800;
}
.review-list-top .top-actions {
}
.review-list-top .top-actions .detailed-link {
	display: inline-flex;
	align-items: center;
}
.review-list-top .top-actions .detailed-link .icon-arrow {
	width: 20px;
	height: 20px;
	background-image: url(../img/mypage/icon_arrow_right.svg);
}
.review-list-top .top-actions .text {
	font-weight: 800;
}
.review-list-top .top-order .text {
	font-weight: 700 !important;
}
.review-list-top .subtext {
	display: flex;
	align-items: center;
}
.review-list-top .subtext.dot .icon-dot {
	display: inline-block;
	width: 3px;
	height: 3px;
	border-radius: 3px;
	background-color: #999;
	margin: 0 10px;
}
.review-list-top .subtext.notice {
	padding-left: 23px;
	font-size: 13px;
	font-weight: 800;
}
.review-list-top .subtext.notice .icon-warning {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	background-image: url(../img/mypage/icon_notice.svg);
	background-size: 20px auto;
}
.review-list-top .number {
	display: inline-block;
	margin-right: 10px;
}
.review-list-top .top-column {
}
.review-list-top .top-column .header-link {
	display: inline-flex;
	align-items: center;
}
.review-list-top .top-column .text {
	font-weight: 800;
}
.review-list-top .top-column .icon-arrow {
	width: 20px;
	height: 20px;
	background-image: url(../img/mypage/icon_arrow_right.svg);
}

.review-card {
	position: relative;
	padding: 30px 0;
}
.review-card .review-row {
	display: flex;
}
.review-card .review-inner {
	flex: 1 1 auto;
}
.review-card .review-top {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.review-card .top-rating {
	display: flex;
	align-items: center;
}
.review-card .top-option {
	position: relative;
	margin-left: 10px;
}
.review-card .top-option .text {
	font-size: 1rem;
	color: #999;
}
.review-card .review-body {
	padding-right: 20%;
}
.review-card .review-body .text {
	max-height: 41px;
	font-size: 1rem;
	line-height: 22px;
	color: #555;
	@include text-ellipsis-2;
}
.review-card .review-bottom {
	display: flex;
	padding-top: 20px;
}
.review-card .review-bottom .bottom-reviewer {
}
.review-card .review-bottom .bottom-reviewer .text {
	font-size: 13px;
}
.review-card .review-bottom .bottom-date {
	margin-left: 10px;
}
.review-card .review-bottom .bottom-date .text {
	font-size: 13px;
}
.review-card .review-image {
	position: relative;
	padding-top: 20px;
}
.review-card .review-image .image-list {
	display: flex;
}
.review-card .review-image .image-item {
	width: 160px;
	height: 160px;
	border-radius: 0.3125rem;
}
.review-card .review-image .image-item ~ .image-item {
	margin-left: 10px;
}
.review-card .review-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.review-card .review-like {
	flex: 0 0 62px;
	width: 62px;
}
.review-card .review-like .btn-like {
	display: block;
}
.review-card .review-like .icon-like {
	width: 62px;
	height: 62px;
	background-image: url(../img/common/icon_review_like.svg);
	margin-bottom: 5px;
}
.review-card .review-like .text {
	display: block;
	font-size: 15px;
	font-weight: 700;
}
.review-card .review-like.is-active {
}
.review-card .review-like.is-active .btn-like {
}
.review-card .review-like.is-active .icon-like {
	background-image: url(../img/common/icon_review_like_active.svg);
}
.review-card .review-like.is-active .text {
	color: #015aa8;
}

.rating-wrapper {
	display: flex;
	align-items: center;
}
.rating-wrapper .rating {
	display: flex;
}
.rating-wrapper .star {
	display: block;
	background-image: url(../img/common/icon_star_default.svg);
	background-position: 0 0;
	background-repeat: no-repeat;
}
.rating-wrapper .star.is-active {
	background-image: url(../img/common/icon_star.svg);
}
.rating-wrapper .rating-text {
	display: block;
	margin-left: 10px;
	transform: translateY(-1px);
}
.rating-wrapper .rating-text .text {
	font-size: 15px;
}
.rating-sm {
}
.rating-sm .star {
	position: relative;
	top: -1px;
	width: 16px;
	height: 16px;
	background-size: 16px auto;
}
.rating-sm .rating-text .text {
	font-weight: bold;
	font-size: 1rem;
	vertical-align: middle;
}
.rating-lg {
}
.rating-lg .star {
	position: relative;
	top: -3px;
	width: 32px;
	height: 32px;
	background-size: 32px auto;
}
.rating-lg .rating-text .text {
	font-size: 30px;
	font-weight: 800;
}

.livestudio-sub-title {
	position: relative;
	padding-top: 40px;
	padding-bottom: 30px;
}
.livestudio-sub-title .badge {
	margin-bottom: 10px;
}
.livestudio-sub-title .title {
	font-size: 40px;
	font-weight: 800;
	line-height: 50px;
}
.livestudio-sub-body {
	display: flex;
	justify-content: space-between;
}
.livestudio-sub-content {
	flex: 0 0 660px;
}
.livestudio-reservator {
	flex: 0 0 480px;
	background-color: #f3f5f7;
}

.livestudio-gallery {
	position: relative;
	margin-bottom: 15px;
}
.livestudio-gallery .gallery-images {
	overflow: hidden;
	width: 660px;
	height: 660px;
}
.livestudio-gallery img {
	width: 660px;
	height: 660px;
	object-fit: cover;
}

.livestudio-gallery .swiper-pagination-bullet {
	background-color: #fff;
	opacity: 0.6;
	text-indent: -9999em;
	overflow: hidden;
	width: 10px;
	height: 10px;
	margin: 0 5px;
}
.livestudio-gallery .swiper-pagination-bullet-active {
	width: 10px;
	height: 10px;
	background-color: #ff7300;
	opacity: 1;
	margin: 0 5px;
}
.livestudio-gallery .gallery-button-next,
.livestudio-gallery .gallery-button-prev {
	transition: all 0.3s ease;
	position: absolute;
	z-index: 1;
	top: 50%;
	margin-top: -25px;
	width: 50px;
	height: 50px;
	border-radius: 2px;
	background-color: #ff7300;
}
.livestudio-gallery .gallery-button-next:hover,
.livestudio-gallery .gallery-button-prev:hover {
	background-color: #555;
}

.livestudio-gallery .gallery-button-next {
	right: -25px;
}
.livestudio-gallery .gallery-button-prev {
	left: -25px;
}
.livestudio-gallery .gallery-button-prev .icon-arrow {
	width: 15px;
	height: 15px;
	background-image: url(../img/common/list_arrow_white.svg);
	background-position: 50%;
	background-size: 15px 15px;
	transform: rotate(-180deg);
}
.livestudio-gallery .gallery-button-next .icon-arrow {
	width: 15px;
	height: 15px;
	background-image: url(../img/common/list_arrow_white.svg);
	background-position: 50%;
	background-size: 15px 15px;
}

.livestudio-description {
}
.livestudio-description-title {
	font-size: 20px;
	font-weight: bold;
	line-height: 30px;
	margin-bottom: 25px;
}
.livestudio-description-contents {
	max-height: 100px;
	overflow: hidden;
}
.livestudio-description-text {
	font-size: 1rem;
	line-height: 22px;
	color: #555;
}

.livestudio-description-morebutton {
	position: relative;
	z-index: 1;
	margin-top: 20px;
}
.livestudio-description-morebutton .btn-more {
	width: 100%;
	background-color: #fff;
	box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}
.livestudio-description-morebutton .icon-arrow {
	width: 10px;
	height: 6px;
	background-image: url(../img/livestudio/description_arrow.svg);
	margin-left: 10px;
}
.livestudio-description-morebutton .btn-more:hover {
	background-color: #ff7300;
}
.livestudio-description-morebutton .btn-more:hover .text {
	color: #fff;
}
.livestudio-description-morebutton .btn-more:hover .icon-arrow {
	background-image: url(../img/livestudio/description_arrow_active.svg);
}

.livestudio-description-contents ~ .livestudio-description-morebutton {
	transform: translateY(-20px);
	margin-top: -20px;
}

.livestudio-warning {
	position: relative;
	padding-top: 50px;
}
.livestudio-warning-title {
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: bold;
	color: #ff7300;
	margin-bottom: 20px;
}
.livestudio-warning-title .icon-warning {
	width: 24px;
	height: 24px;
	background-image: url(../img/common/warning_alarm.svg);
	margin-right: 10px;
}
.livestudio-warning-description {
	font-size: 1rem;
	line-height: 22px;
	color: #555;
}

.livestudio-reservator {
	position: relative;
	border: 1px solid #ebebeb;
}
.livestudio-reservator .month-selector {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 60px;
}
.livestudio-reservator .month-selector .btn-selector .icon-arrow {
	width: 30px;
	height: 30px;
	background-image: url(../img/livestudio/month_arrow.svg);
}
.livestudio-reservator .month-selector .btn-selector-prev .icon-arrow {
	transform: rotate(-180deg);
}
.livestudio-reservator .month-selector .calendar-title {
	width: 160px;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
}
.livestudio-reservator .livestudio-calendar {
	padding: 30px 15px 10px;
}
.livestudio-reservator .ls-calendar th,
.livestudio-reservator .ls-calendar td {
	width: 65px;
	height: 65px;
	text-align: center;
}
.livestudio-reservator .ls-calendar .date {
	position: relative;
	display: flex;
	width: 48px;
	height: 48px;
	margin: auto;
	justify-content: center;
	padding-top: 10px;
}
.livestudio-reservator .calendar-date .num {
}
.livestudio-reservator .calendar-date .text {
	position: absolute;
	left: 50%;
	font-size: 10px;
	white-space: nowrap;
	bottom: 5px;
	transform: translateX(-50%);
}
.livestudio-reservator .calendar-date-disabled * {
	color: #999;
}
.livestudio-reservator .calendar-date:not(.calendar-date-disabled):not(.calendar-date-selected) .date:hover {
	background-color: #ebebeb;
}
.livestudio-reservator .calendar-date-selected .date {
	background-color: #015aa8;
}
.livestudio-reservator .calendar-date-selected .date * {
	color: #fff;
}
.livestudio-reservator th.calendar-sun,
.livestudio-reservator td.calendar-sun * {
	color: #999;
}
.livestudio-reservator th.calendar-sat,
.livestudio-reservator td.calendar-sat * {
	color: #999;
}

.livestudio-reservator .livestudio-buttons {
	margin: 20px 30px 0;
	padding-bottom: 60px;
}
.livestudio-reservator .livestudio-buttons .btn {
	width: 48%;
	margin-left: 4px;
	margin-right: 4px;
}

.livestudio-time-selector {
	position: relative;
	border-top: 1px solid #ebebeb;
	padding: 20px 30px;
}

.time-selector-list {
}
.time-selector-item:not(:first-child) {
	margin-top: 10px;
}
.time-selector-check {
	position: relative;
}
.time-selector-input {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
}
.time-selector-label {
	display: block;
	width: 100%;
	padding: 20px;
	background-color: #fff;
	cursor: pointer;
	height: 100px;
	transition: background-color 0.4s;
}
.time-selector-label .time {
	display: block;
	font-size: 20px;
	color: #111;
	font-weight: bold;
	margin-bottom: 22px;
}
.time-selector-label .time-status {
	font-size: 13px;
	color: #ff7300;
	font-weight: bold;
}
.time-selector-input:not(:checked) ~ .time-selector-label:hover {
	box-shadow: 0 0 0 1px #999;
}
.time-selector-input:checked ~ .time-selector-label {
	background-color: #015aa8;
	background-image: url(../img/livestudio/icon_timecheck.svg);
	background-repeat: no-repeat;
	background-position: calc(100% - 35px) 50%;
}
.time-selector-input:checked ~ .time-selector-label * {
	color: #fff;
}
.time-selector-input:disabled ~ .time-selector-label * {
	color: #999;
}
.time-selector-input:focus ~ .time-selector-label {
	outline: none;
}
