#sme-apply .page-component {min-height:calc(100vh - 466px);display:flex;flex-direction:column;}
#sme-apply .page-component .no-results-found {margin-bottom:-100px;flex:auto;display:flex;align-items:center;justify-content:center;}
#sme-apply .apply-header {position:relative;padding-top:40px}
#sme-apply .apply-header .title{font-size:25px;line-height:32px;font-weight:900}
#sme-apply .apply-header .form-select {width:335px;box-shadow:0 0 0 2px #015aa8 inset;color:#015aa8}
#sme-apply .apply-list-wrapper {position:relative;padding-top:30px}
#sme-apply .apply-list-wrapper .apply-list {position:relative;justify-content: center;}
#sme-apply .apply-list-wrapper .apply-list-item:not(:first-child) {border-top:1px solid #ebebeb;}
#sme-apply .apply-list-wrapper .item-title {position:relative;font-size:25px;line-height:32px;font-weight:bold;}
#sme-apply .apply-list-wrapper .item-link {position:absolute;right:0;bottom:40px;display:inline-flex;align-items:center;color:#015aa8;transition: all .3s ease;}
#sme-apply .apply-list-wrapper .item-link:hover {color:#FF7300;}
#sme-apply .apply-list-wrapper .item-link-text {font-size:20px;font-weight:900;}
#sme-apply .apply-list-wrapper .item-link:hover .icon-arrow {background-image:url(../img/apply/apply_arrow_active.svg);}
#sme-apply .apply-list-wrapper .icon-arrow {width:22px;height:22px;background-image:url(../img/apply/apply_arrow.svg);margin-left:10px;transition: all .3s ease;}
#sme-apply .apply-list-wrapper .apply-btns button:first-child {margin-right:10px;}

/* 220603 중소벤처기업부 디자인 변경 */
#sme-apply .apply-header{display: flex; justify-content: space-between; align-items: flex-end;}
#sme-apply .apply-header .btn_search{position: relative;}
#sme-apply .apply-header .btn_search .form_search{display: block;width: 100%;padding: 20px 15px;background-color: #fff;border: none;border-bottom: 1px solid #cccccc;font-weight: bold;color: #111;}
#sme-apply .apply-header .btn_search .form_search:focus {outline: 2px solid #015aa8;}
#sme-apply .apply-header .ico_search{width: 25px;height: 25px; position: absolute;top: 50%; right: 5%; background: url(../img/common/search_icon.svg) no-repeat; transform: translate(0, -50%); text-indent: -9999rem;}
#sme-apply .apply-list-wrapper .apply-list{display: flex;flex-wrap: wrap;align-items: center;}
#sme-apply .apply-list-wrapper .apply-list-item{width: 305px;height: 410px; margin: 0 20px 15px 0;border: 1px solid #ddd;border-radius: 15px; cursor: pointer;}
#sme-apply .apply-list-wrapper .apply-list-item:hover{border: 1px solid #0457a5;}

/*#sme-apply .apply-list-wrapper .apply-list-item:hover .item-category, #sme-apply .apply-list-wrapper .apply-list-item:hover .apply-items{background: #f1f8fe;}*/
#sme-apply .apply-list-wrapper .apply-list-item:nth-child(4n){margin-right: 0;}
#sme-apply .apply-list-wrapper .thumbail_img {display:block;overflow:hidden; border-top-left-radius: 15px; border-top-right-radius: 15px;}
#sme-apply .apply-list-wrapper .thumbail_img img{transition:transform .4s ease; width: 303px; height:180px; object-fit: fill;padding-bottom: 0; border-top-left-radius: 15px; border-top-right-radius: 15px;}
#sme-apply .apply-list-wrapper .thumbail_img img:focus,
#sme-apply .apply-list-wrapper .thumbail_img img:hover{transform:scale(1.065) rotate(0.01deg);}




#sme-apply .apply-list-wrapper .item-category{padding: 10px 10px 0 10px;}
#sme-apply .apply-list-wrapper .item-category span{display:inline-block;margin: 0 10px 12px 0;padding: 5px 10px; background: #fbe6d9; border: 1px solid #ff7300;border-radius: 20px; font-size:14px;color:#ff7300;font-weight:bold; text-overflow: ellipsis; white-space: nowrap; max-width: 280px; overflow: hidden;}
#sme-apply .apply-list-wrapper .apply-items {padding: 5px 10px 50px 10px;position: relative; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;}
#sme-apply .apply-list-wrapper .item-title {height: 50px; font-size:21px;line-height:25px;font-weight:bold;}
#sme-apply .apply-list-wrapper .item-content{margin-top: 10px; font-size: 14px;line-height: 21px;}
#sme-apply .apply-list-wrapper .item-date{position: absolute;bottom: 20px;left: 10px; font-size: 12px;}
.ellipsis2 {display: -webkit-box;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}


#sme-apply .support-title {display:flex;justify-content:left;}
#sme-apply .support-title .support-title-desc {width:800px;padding:10px;line-height:25px;font-weight:700;border-radius:2px;background-color: white;font-size:20px; color:#111;text-align: left;}



/* 중앙부처, 지자체 지원사업 안내 */
.second_wrap .tab_wrap{display: flex;margin: 30px 0;align-items: center;}
.second_wrap .tab_wrap .tab_list{width: 100%; margin-right: 65px; height: 40px;background: #bbb;border: 1px solid #aaa; color: #fff;}
.second_wrap .tab_wrap li:first-child .tab_list{border-top-left-radius: 8px;border-bottom-left-radius: 8px;}
.second_wrap .tab_wrap li:last-child .tab_list{border-top-right-radius: 8px;border-bottom-right-radius: 8px;}
.second_wrap .tab_wrap .tab_list.on, .second_wrap .tab_wrap .tab_list:hover{background: #0457a5;border: 1px solid #0457a5;}

.second_wrap .tbl_wrap table{width: 100%; text-align: center;}
.second_wrap .tbl_wrap table tr{height: 50px;border-bottom: 1px solid #dce2e8;}
.second_wrap .tbl_wrap table th{position: relative;}
.second_wrap .tbl_wrap table th::after{display: block;width: 1px;height: 20px; position: absolute;top: 0; right: -2px;background: #94a3f9; content: "";clear: both;}
.second_wrap .tbl_wrap table th:last-child::after{display: none;}

.second_wrap .tbl_wrap table td{ position: relative;cursor: text;}
.second_wrap .tbl_wrap table td:after{display: block;width: 1px;height: 20px; position: absolute;top: 0; right: -2px;background: #dce2e8; content: "";clear: both;}
.second_wrap .tbl_wrap table td:last-child::after{display: none;}
.second_wrap .tbl_wrap thead tr{background: #f3f9ff; border-top: 2px solid #94a3f9; box-shadow: 0 2px 5px rgb(184 196 251 / 100%);}


.second_wrap .tbl_wrap tbody td{padding: 15px 20px; text-align: center;}
.second_wrap .tbl_wrap tbody td:first-child{border-left: none;}
.second_wrap .tbl_wrap tbody td:last-child{border-right: none;}
.second_wrap .tbl_wrap .title{text-align-last: left;}
/* .second_wrap .tbl_wrap .title:hover{color: #0457a5;text-decoration: underline;} */
.btn-detail{padding: 8px 30px;border-radius: 20px;}
