[class*='-component'] {
	position: relative;
	margin: auto;
	padding: 0 60px;
	width: 1400px;
} /* 1280px */

[class^='icon-'] {
	overflow: hidden;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 0 0;
	text-indent: -9999em;
}

.btn-icontext {
	display: inline-flex;
	align-items: center;
}
.btn-icontext [class^='icon'] {
}
.btn-texticon {
	display: inline-flex;
	align-items: center;
}

#gachi {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	height: 101vh;
}

.preload * {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
}

/* gachi-header */
.gachi-header {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100px;
	// max-height: 100px;
	z-index: 500;
	background-color: rgba(0, 0, 0, 0.3);
	@include transition(all, 0.5s);
	.header-component {
		position: initial;
		overflow: visible;
		display: flex;
		width: auto;
		height: 100%;
		padding: 0 60px;
		align-items: center;
		justify-content: space-between;
	}
	.header-gnb {
		z-index: 499;
		height: 100%;
	}
	.header-logo {
		flex: 0 0 130px;
		position: relative;
		.logo-gachi {
			display: block;
			width: 130px;
			height: 30px;
			background: url(../img/common/logo_w.svg) no-repeat 0 0;
			text-indent: -9999em;
			overflow: hidden;
			&::after {
				display: inline-block;
				width: 22px;
				height: 17px;
				content: '';
				background: url(../img/common/portal.svg);
				position: absolute;
				right: -18px;
				bottom: 20px;
				background-repeat: no-repeat;
			}
		}
	}
	.header-menu {
		flex: auto;
		text-align: center;
	}
	.header-menu-list {
		display: inline-flex;
		align-items: center;
	}
	.header-menu-item {
		position: relative;
		// &.is-active .header-menu-link {
		// 	box-shadow: 0 -2px 0 0px #015aa8 inset;
		// }
		&:not(:first-child) .header-menu-link {
			margin-left: 20px;
		}
	}
	.header-menu-link {
		height: 60px;
		display: flex;
		align-items: center;
		box-shadow: 0 -2px 0 0px transparent inset;
		transition: all 0.4s;
	}
	.header-menu-text {
		font-size: 19px;
		font-weight: 700;
		// color: #fff;
		font-family: 'Pretendard';
	}
	.menu-market {
		display: inline-block;
		width: 22px;
		height: 17px;
		background: url(../img/common/market.svg) no-repeat 0 0;
		text-indent: -9999em;
		position: relative;
		bottom: 8px;
	}
	.header-util {
		flex: 0 0 130px;
	}
	.header-util-list {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.header-util-item:not(:first-child) {
		margin-left: 15px;
	}
	.icon-util-search {
		width: 25px;
		height: 25px;
		background-image: url(../img/common/search_w.svg);
	}
	.icon-util-faq {
		width: 50px;
		height: 25px;
		background-image: url(../img/common/faq_w.svg);
	}
	.icon-util-notice {
		width: 25px;
		height: 22px;
		background-image: url(../img/common/notice_w.svg);
	}
	.icon-util-mypage {
		width: 25px;
		height: 25px;
		background-image: url(../img/common/mypage_w.svg);
	}
	.header-sub {
		position: absolute;
		top: 0px;
		left: 50%;
		z-index: 499;
		height: 45px;
		transform: translateX(-50%);
		margin-left: 10px;
		@include tablet {
			margin-top: -20px;
		}
		&-list {
			display: flex;
			height: 100%;
			margin: auto;
			opacity: 0;
			visibility: hidden;
			align-items: center;
			gap: 10px;
		}
		&-item:not(:first-child) {
			margin-left: 20px;
		}
		&-text {
			font-size: 18px;
			color: #f7f7f7;
			font-weight: 400;
			white-space: nowrap;
			font-family: 'Pretendard';
		}
		&-link {
			&:hover {
				.header-sub-text {
					color: #fff;
					font-weight: 700;
				}
			}
		}
		&-overlay {
			z-index: 490;
			visibility: hidden;
			opacity: 0;
			position: absolute;
			top: 100%;
			height: 0;
			left: 0;
			width: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			@include transition(all, 0.2s);
			&.is-active {
				visibility: visible;
				opacity: 1;
				height: 45px;
			}
		}
	}
	.header-menu-item:first-child .header-sub {
		left: 0;
		transform: translateX(0);
		margin-left: 0;
	}

	.header-banner {
		position: fixed;
		background-color: #ff7300;
		transform: translateY(-60px);
		left: 0;
		width: 100%;
		z-index: 200;
		transition: transform 0.4s;
		top: 0;
		.header-component {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.banner-link {
			display: flex;
			height: 60px;
			align-items: center;
			flex: auto;
			&:hover {
				text-decoration: underline;
			}
		}
		.banner-text {
			font-weight: bold;
			color: #fff;
		}
		.icon-banner-noti {
			width: 24px;
			height: 24px;
			background-image: url(../img/common/header_banner_noti.svg);
			margin-right: 10px;
		}
		.icon-banner-close {
			width: 30px;
			height: 30px;
			background-image: url(../img/common/header_banner_close.svg);
		}
		&.is-visible {
			transform: translateY(0px);
		}
	}

	&.is-active {
		height: 60px;
		max-height: 60px;
		// background-color: #f3f5f7;
		.header-gnb {
			background-color: #fff;
		}
		.header-sub {
			top: 80px;
			&-list {
				opacity: 1;
				visibility: visible;
			}
		}
		.header-menu-text {
			color: #000;
		}

		.header-logo .logo-gachi {
			visibility: visible;
			opacity: 1;
		}
		// .header-logo .logo-gachi {
		// 	background-image: url(../img/common/logo.svg);
		// }
		.header-menu-text {
			color: #111;
		}
		.icon-util-search {
			background-image: url(../img/common/search.svg);
		}
		.icon-util-faq {
			background-image: url(../img/common/faq.svg);
		}
		.icon-util-notice {
			background-image: url(../img/common/notice.svg);
		}
		.icon-util-mypage {
			background-image: url(../img/common/mypage.svg);
		}
	}

	.header-sub-cont {
		display: flex;
	}
	.header-sub-arrow {
		display: inline-block;
		width: 16px;
		height: 16px;
		margin-left: 3px;
		img {
			display: inline;
			width: 16px;
			height: 16px;
		}
	}

	&[data-header='sticky'] {
		// position: sticky;
		// position: -webkit-sticky;
		top: 0;
		box-shadow: 0 -2px 0 0 #ebebeb inset;
		background-color: #fff;
	}

	&[data-header='min'] {
		position: relative;
		.header-gnb {
			justify-content: center;
			padding-top: 40px;
		}
		.logo-gachi {
			//background-image: url(../img/common/logo.svg);
			background-image: url(~@/assets/img/renew/logo.svg);
		}
	}
	&[data-header='sticky'] {
		position: relative;
		// position: -webkit-sticky;
		top: 0;
		box-shadow: 0 -2px 0 0 #ebebeb inset;
		background-color: #fff;
		.header-logo .logo-gachi {
			//background-image: url(../img/common/logo.svg);
			background-image: url(~@/assets/img/renew/logo.svg);
		}
		.header-menu-text {
			color: #111;
		}
		.icon-util-search {
			background-image: url(../img/common/search.svg);
		}
		.icon-util-faq {
			background-image: url(../img/common/faq.svg);
		}
		.icon-util-notice {
			background-image: url(../img/common/notice.svg);
		}
		.icon-util-mypage {
			background-image: url(../img/common/mypage.svg);
		}
	}
	&[data-header='dark'] {
		.header-logo .logo-gachi {
			//background-image: url(../img/common/logo.svg);
			background-image: url(~@/assets/img/renew/logo.svg);
		}
		.header-menu-text {
			color: #111;
		}
		.icon-util-search {
			background-image: url(../img/common/search.svg);
		}
		.icon-util-faq {
			background-image: url(../img/common/faq.svg);
		}
		.icon-util-notice {
			background-image: url(../img/common/notice.svg);
		}
		.icon-util-mypage {
			background-image: url(../img/common/mypage.svg);
		}
	}
}

/* gachi-footer */
.gachi-footer {
	position: relative;
	flex: 0 0 auto;
}
.gachi-footer .footer-family {
	overflow-x: auto;
	position: relative;
	background-color: white;
}
.gachi-footer .footer-family-list {
	display: flex;
	width: 1300px;
	height: 70px;
	align-items: center;
	justify-content: center;
}
.gachi-footer .footer-family-item {
}
.gachi-footer .footer-family-item:not(:first-child) {
	margin-left: 80px;
}
.gachi-footer .footer-family-link {
	display: block;
}
.gachi-footer .footer-family-link img {
	width: auto;
	height: 30px;
	transition-property: opacity;
	transition-duration: 0.3s;
	transition-timing-function: ease-in-out;
}

.gachi-footer .footer-family-link:hover img {
	opacity: 0.98;
	transition-property: opacity;
	transition-duration: 0.3s;
	transition-timing-function: ease-in-out;
}

.gachi-footer .footer-row {
	position: relative;
	padding: 50px 0 40px;
	background-color: #f3f5f7;
}
.gachi-footer .footer-logo {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 30px;
}
.gachi-footer .footer-logo .logo-link {
	display: inline-block;
}
.gachi-footer .footer-logo .logo-link:not(:first-child) {
	margin-left: 30px;
}
.gachi-footer .footer-logo .logo-mss {
	overflow: hidden;
	text-indent: -9999em;
	display: inline-block;
	width: 154px;
	height: 40px;
	background: url(../img/common/footer_logo_01.svg) no-repeat 0 0;
}
.gachi-footer .footer-logo .logo-sbdc {
	overflow: hidden;
	text-indent: -9999em;
	display: inline-block;
	width: 185px;
	height: 31px;
	background: url(../img/common/footer_kodma_logo.png) no-repeat 0 0;
	background-size: auto 31px;
}
.gachi-footer .footer-description {
	position: relative;
	margin: 0 auto;
	text-align: center;
	// width: 1280px;
}
.gachi-footer .footer-description .text {
	font-size: clamp(5px, 2vw, 13px);
	line-height: 1.6;
	@include word-wrap;
}
.gachi-footer .footer-description .text a:hover {
	color: #015aa8;
	transition: color 0.2s;
}

/* page-container */
.page-container {
	position: relative;
	flex: auto;
	padding-bottom: 80px;
	.container {
		width: 100%;
		max-width: 1400px;
		margin: 0 auto;
		padding: 0 40px;
		@include tablet {
			padding: 0 25px;
		}
	}
}

.gachi-skipnav {
	position: absolute;
	left: 0;
	top: -50px;
	text-indent: -9999em;
	font-size: 0;
	width: 100%;
	z-index: 999;
}
.gachi-skipnav li a:active,
.gachi-skipnav li a:focus {
	position: absolute;
	left: 0;
	top: 50px;
	display: block;
	width: 100%;
	padding: 20px 0;
	text-align: center;
	text-indent: 0px;
	font-weight: bold;
	background-color: #015aa8;
	color: #fff;
	font-size: 1rem;
}

.page-header {
	// padding: 60px 0;
	padding: 40px 0;
	background-color: #fff;
}
.page-header .page-header-title {
	text-align: center;
}
.page-header .page-header-title .title {
	font-size: 40px;
	line-height: 55px;
	font-weight: 900;
}
.page-header .page-header-title .description {
	font-size: 20px;
	margin-top: 10px;
	line-height: 22px;
	color: #555;
}
.page-header .page-header-description {
	position: relative;
	padding-top: 10px;
	text-align: center;
}
.page-header .page-header-description .text {
	font-size: 1rem;
	font-weight: bold;
	color: #999;
}
.page-header .page-header-hashtags {
	position: relative;
	padding-top: 10px;
	text-align: center;
}
.page-header .page-header-hashtags .text {
	font-size: 1rem;
	font-weight: bold;
	color: #999;
}

.page-body[data-fill='true'] {
	background-color: #f3f5f7;
}

.page-body[data-fill='true'] {
	margin-bottom: -120px;
	padding-bottom: 120px;
}

.gachi-search {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 600;
	backdrop-filter: blur(10px);
	background-color: rgba(255, 255, 255, 0.9);
	bottom: 0;
}
.gachi-search .search-header {
	position: relative;
	padding-top: 35px;
	padding-bottom: 75px;
}
.gachi-search .search-header .logo-link {
	display: block;
	width: 130px;
	height: 30px;
}
.gachi-search .search-header .logo {
	display: block;
	width: 130px;
	height: 30px;
	background: url(../img/common/logo.svg) no-repeat 0 0;
}
.gachi-search .search-header .btn {
	position: absolute;
	right: 0;
	top: 35px;
}
.gachi-search .search-header .icon-close {
	width: 30px;
	height: 30px;
	background-image: url(../img/common/icon_close.svg);
}
.gachi-search .search-field {
	display: flex;
	width: 800px;
	margin: 0 auto;
	padding-bottom: 50px;
}
.gachi-search .search-field .form-control {
	flex: auto;
	background: #fff url(../img/common/search_icon.svg) no-repeat 20px 50%;
	padding-left: 65px;
	box-shadow: 0 0 0 2px #015aa8 inset;
}
.gachi-search .search-results {
	padding-top: 40px;
}
.gachi-search .search-results .page-component {
	padding: 0 100px;
}
.gachi-search .section-result {
	position: relative;
	padding: 50px 60px;
	background-color: #fff;
}
.gachi-search .section-result ~ .section-result {
	margin-top: 10px;
}
.gachi-search .section-result .section-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.gachi-search .section-result .section-header .header-title {
	font-size: 30px;
	font-weight: 900;
}
.gachi-search .search-list {
	padding-top: 40px;
}
.gachi-search .search-list[data-list='thumbnail'] {
	padding-top: 27px;
}
.gachi-search .search-item {
	padding: 20px 0;
}
.gachi-search .search-item:last-child {
	padding-bottom: 0;
}
.gachi-search .search-item ~ .search-item {
	border-top: 1px solid #ebebeb;
}
.gachi-search .search-item .item-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.gachi-search .search-item .item-subtext {
	color: #999;
	line-height: 22px;
}
.gachi-search .search-item .item-title {
	margin-top: 10px;
	font-size: 25px;
	font-weight: bold;
}
.gachi-search .search-item .image-link {
	display: block;
	width: 100px;
	height: 100px;
	border-radius: 2px;
}
.gachi-search .search-item .image-link img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.gachi-search .search-item .text-link {
	display: inline-block;
	margin-top: 32px;
}
.gachi-search .search-item .text-link .text {
	font-size: 20px;
	font-weight: 900;
}
.gachi-search .search-item .text-link .icon-arrow {
	width: 20px;
	height: 20px;
	background-image: url(../img/common/list_arrow.svg);
	background-size: 20px auto;
}
.gachi-search .search-item:hover {
}
.gachi-search .notfound-result {
	position: relative;
	padding-top: 40px;
}
.gachi-search .notfound-result .text {
	font-size: 25px;
	font-weight: bold;
	color: #999;
}

.gachi-search .section-community .item-title {
	font-weight: normal;
}
.gachi-search .section-creator .creator-list {
	padding-top: 40px;
}

.gachi-search[data-search='results'] {
	position: relative;
	background-color: #f3f5f7;
	padding-bottom: 120px;
}
.gachi-search[data-search='results'] {
}
.gachi-search[data-search='results'] .search-top {
	background-color: #fff;
}

.ie .gachi-header[data-header='sticky'] {
	position: fixed;
}
.ie .gachi-header[data-header='sticky'] ~ .page-container {
	padding-top: 102px;
}

.no-results-found {
	position: relative;
	padding: 120px 0;
	text-align: center;
}
.no-results-found .text {
	font-size: 25px;
	color: #999;
	font-weight: 700;
}

/* SNS 윙배너 (20220920 hib) */
.snsWing {
	position: fixed;
	right: 9.5%;
	bottom: 4%;
	width: 40px;
	height: 40px;
	z-index: 1;
}
.sns-quick {
	display: flex;
	position: fixed;
	right: 8.5%;
	bottom: 4%;
	width: 85px;
	height: 130px;
	z-index: 1;
	gap: 5px;
	flex-wrap: wrap;
	> a,
	> button {
		display: inline-flex;
		width: 40px;
		height: 40px;
	}
}

.arrow {
	border: solid #424242;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	&-down {
		transform: rotate(45deg);
	}
	&-right {
		transform: rotate(-45deg);
	}
	&-up {
		transform: rotate(-135deg);
	}
	&-left {
		transform: rotate(135deg);
	}
}
