.box_logo a {
	display: inline-block;
}
.box_logo span {
	display: block;
	width: 230px;
	height: 55px;
	background: url('../img/common/footer_sbdc_logo.png') no-repeat;
	text-indent: -9999em;
	overflow: hidden;
	background-size: auto 60px;
}

.box_title {
	padding: 25px 0 10px;
	margin-top: 60px;
	background: #eee;
}
.box_title h2 {
	line-height: 65px;
	font-size: 60px;
	font-family: 'GmarketSans';
	font-weight: 700;
	color: #285e3b;
	-webkit-text-stroke: 2px white;
}
.box_title h2 span {
	display: block;
	-webkit-text-stroke: 4px white;
}

.self_wrap {
	width: 1020px;
	display: flex;
	margin: 0 auto;
}
.self_wrap ul {
	padding: 0 10px;
}
.self_wrap ul li {
	padding: 50px 0;
	position: relative;
}
.self_wrap ul li a {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.self_wrap .mango li::before {
	display: inline-block;
	width: 1901px;
	height: 555px;
	background: #eee;
	content: '';
	position: absolute;
	top: 0px;
	left: -450px;
}
.self_wrap .mango li:nth-child(2)::before {
	height: 300px;
	top: 50px;
}
.self_wrap .mango li:last-child::before {
	height: 220px;
	top: 20px;
}

.self_wrap .mango li::after {
	width: 141px;
	padding: 8px 30px 3px;
	font-size: 21px;
	font-family: 'GmarketSans';
	border-radius: 50px;
	color: #fff;
	background: #285e3b;
	position: absolute;
	content: '바로가기';
	top: -15px;
	right: -80px;
}
.self_wrap .mango li:nth-child(2)::after {
	content: '이용안내';
	top: 35px;
}
.self_wrap .mango li:last-child::after {
	content: '학습하기';
	top: 5px;
}
.self_wrap .miri li:not(:first-of-type)::before {
	height: 430px;
	border-left: 4px dashed #b2afaf;
	content: '';
	position: absolute;
	top: 90px;
	left: -12px;
}
.self_wrap .miri li:last-child::before {
	height: 1000px;
	top: 75px;
}

.self_wrap .page_link {
	padding: 20px;
	border: 3px solid #f7ca3d;
	border-radius: 30px;
	background: #fff;
	position: relative;
}
.self_wrap .page_link span {
	display: inline-block;
	margin-bottom: 20px;
	font-size: 40px;
	font-family: 'GmarketSans';
	font-weight: 700;
	color: #f7ca3d;
}
.self_wrap .page_link p {
	line-height: 28px;
	font-size: 18px;
	font-family: 'GmarketSans';
}
.self_wrap .miri .page_link {
	border-color: #4fd2b3;
}
.self_wrap .miri .page_link span {
	color: #4fd2b3;
}

.self_wrap .page_img {
	width: 490px;
	height: 245px;
	margin-top: 30px;
	position: relative;
}
.self_wrap .page_img img {
	width: 100%;
	height: 100%;
	border-radius: 30px;
}

.self_wrap .manual {
	padding: 0 80px;
	position: relative;
}
.self_wrap .manual p {
	display: inline-block;
	margin-right: 25px;
	line-height: 35px;
	font-size: 29px;
	font-family: 'GmarketSans';
	font-weight: 700;
	color: #285e3b;
}
.self_wrap .manual .manual_icon {
	padding: 30px;
}
.self_wrap .miri .manual .manual_icon {
	margin-bottom: 10px;
}

.self_wrap .video_link {
	width: 433px;
	margin: 20px auto;
	text-align: center;
	position: relative;
}
.self_wrap .video_link p {
	padding: 20px 0 20px;
	font-size: 25px;
	font-family: 'GmarketSans';
	background: #f7ca3d;
}
.self_wrap .video_link span {
	padding: 6px 20px 2px;
	font-size: 18px;
	font-family: 'GmarketSans';
	border-radius: 50px;
	color: #fff;
	background: #285e3b;
	position: relative;
	bottom: 5px;
}

.box_logo.box_logo_gachi {
	text-align: center;
}
.box_logo.box_logo_gachi span {
	width: 232px;
	height: 52px;
	background: url('../img/common/logo.svg') no-repeat;
	background-size: auto 55px;
}
