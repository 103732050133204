.swiper-pagination-bullet {
	background-color: #fff;
	opacity: 0.6;
	text-indent: -9999em;
	overflow: hidden;
	width: 5px;
	height: 5px;
}
.swiper-pagination-bullet-active {
	background-color: #ff7300;
	opacity: 1;
}

.community-list {
	display: flex;
	flex-wrap: wrap;
	margin: -15px;
}
.community-list-item {
	width: 33.3%;
	padding: 15px;
}
.community-card {
	position: relative;
	padding: 20px;
	background-color: #fff;
	border-radius: 2px;
}

.community-card .community-content {
	padding-bottom: 15px;
}
.community-card .community-content .badge {
	margin-bottom: 15px;
}
.community-card .community-content .text {
}
.community-card .community-content .title-link {
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 22px;
	font-size: 15pt;
	white-space: nowrap;
}
.community-card .community-images {
	overflow: hidden;
	position: relative;
}
.community-card .community-image-wrapper {
}
.community-card .community-image {
	overflow: hidden;
	// height: 200px;
	// height: 271px;
	height: 426px;
}
.community-card .community-image img {
	width: 100%;
	height: 100%; /*object-fit:cover;*/
	transition: transform 0.4s ease;
}
.community-card .community-image:hover img {
	transform: scale(1.065) rotate(0.01deg);
}
.community-card .community-info {
	padding: 15px 0;
}
.community-card .community-info .info-row {
	display: flex;
	min-width: 0;
}
.community-card .community-info .info-item {
	position: relative;
	font-size: 13px;
	color: #555;
}
.community-card .community-info .info-item.writer {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.community-card .community-info .info-item:not(.writer) {
	flex-shrink: 0;
}
.community-card .community-info .info-item:not(:first-child):before {
	content: '·';
	display: inline-block;
	margin-right: 2px;
	margin-left: 4px;
}
.community-card .community-actions {
	position: relative;
	border-top: 1px solid #ebebeb;
	display: flex;
	padding-top: 20px;
}
.community-card .community-actions .actions-btn {
	display: inline-flex;
	align-items: center;
}
.community-card .community-actions .actions-btn:not(:first-child) {
	margin-left: 15px;
}
.community-card .community-actions .icon-reply {
	width: 20px;
	height: 20px;
	background-image: url(../img/community/reply_icon.svg);
	margin-right: 10px;
}
.community-card .community-actions .icon-message {
	width: 20px;
	height: 20px;
	background-image: url(../img/community/message_icon.svg);
	margin-right: 10px;
}
.community-card .community-actions .actions-text {
	font-size: 13px;
	font-weight: 900;
	color: #015aa8;
}
.community-card .community-actions .actions-btn:hover .actions-text {
	color: #ff7300;
}
.community-card .community-actions .actions-btn:hover .icon-message {
	background-image: url(../img/community/message_icon_active.svg);
}

#sme-community {
	background-color: #f3f5f7;
}

#sme-community {
}
#sme-community .page-component {
	padding: 0 120px;
}
#sme-community .community-banner {
	position: relative;
}
#sme-community .community-banner .banner-content {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 400px;
	padding-top: 150px;
}
#sme-community .community-banner .banner-title {
	font-size: 35px;
	font-weight: 800;
	color: #fff;
	line-height: 50px;
}
#sme-community .community-banner .banner-image {
	line-height: 0;
}
#sme-community .community-banner .banner-image img {
	width: 100%;
	height: 400px;
	object-fit: cover;
}
#sme-community .community-list-wrapper {
}

#sme-community .community-title {
	display: flex;
	margin: 20px 0 10px;
	justify-items: center;
}
#sme-community .community-title > div {
	flex: 1 1;
}
#sme-community .community-title .community-title-desc {
	width: 100%;
	padding: 10px;
	border-radius: 2px;
	background-color: white;
	line-height: 25px;
	font-weight: 700;
	font-size: 20px;
	color: #111;
	text-align: center;
}
#sme-community .community-title .community-title-desc img {
	width: 70%;
	margin-top: 10px;
}

#sme-community .is-write {
	display: flex;
}
#sme-community .community-write-wrap {
	display: flex;
	padding: 2px 0 0;
	justify-content: flex-end;
	align-items: center;
}
#sme-community .community-write {
	height: 45px;
	border-radius: 5px;
	background-color: #ff7300;
}

#sme-community-sub {
}
#sme-community-sub .community-sub-header {
	position: relative;
	height: 400px;
	text-align: center;
}
#sme-community-sub .community-sub-header .page-component {
	padding-top: 100px;
	position: relative;
	z-index: 1;
	padding-bottom: 240px;
}
#sme-community-sub .community-header-background {
	overflow: hidden;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
#sme-community-sub .community-header-background img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	filter: blur(15px);
	-webkit-filter: blur(15px);
}
#sme-community-sub .community-sub-header .badge {
	margin-bottom: 15px;
}
#sme-community-sub .community-sub-header .title {
	font-size: 50px;
	line-height: 65px;
	font-weight: bold;
	color: #fff;
}
#sme-community-sub .community-sub-header .info {
	margin-top: 17px;
}
#sme-community-sub .community-sub-header .info-text {
	color: #fff;
	font-size: 1rem;
	font-weight: bold;
}
#sme-community-sub .community-sub-body {
	position: relative;
	width: 1200px;
	padding: 50px 150px 0;
	margin-top: -100px;
	background-color: #fff;
	z-index: 10;
}
#sme-community-sub .community-body-actions {
	padding: 30px 0;
	display: flex;
}
#sme-community-sub .community-body-actions .icon-reply {
	margin-right: 10px;
	width: 30px;
	height: 30px;
	background-image: url(../img/community/reply_icon_60h.svg);
	background-size: 30px auto;
}
#sme-community-sub .community-body-actions .icon-message {
	margin-right: 10px;
	width: 30px;
	height: 30px;
	background-image: url(../img/community/message_icon_60h.svg);
	background-size: 30px auto;
}
#sme-community-sub .community-body-actions .text {
	font-weight: 900;
}
#sme-community-sub .community-body-actions .btn:not(:first-child) {
	margin-left: 10px;
}
#sme-community-sub .page-buttons {
	justify-content: center;
}

.comment-container {
	position: relative;
}
.comment-container .comment-list {
	text-align: left;
}
.comment-container .comment-item {
	display: flex;
	align-items: top;
}
.comment-container .comment-item:first-child {
	padding-top: 30px;
	border-top: 1px solid #ddd;
}
.comment-container .comment-item ~ .comment-item {
	padding-top: 10px;
}
.comment-container .comment-column.avatar {
	flex: 0 0 80px;
}
.comment-container .comment-column.avatar img {
	width: 80px;
	height: 80px;
	object-fit: cover;
}
.comment-container .comment-column.contents {
	flex: auto;
	padding-left: 20px;
	min-height: 80px;
	padding-top: 10px;
}
.comment-container .comment-column.control {
	flex: 0 0 100px;
	align-self: center;
}
.comment-container .comment-metadata {
	margin-bottom: 20px;
}
.comment-container .comment-author {
	font-size: 20px;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
}
.comment-container .comment-author .badge {
	margin-left: 15px;
	border: 1px solid #ebebeb;
	border-radius: 1px;
	background-color: #f3f5f7;
}
.comment-container .comment-date {
	display: block;
	font-size: 16px;
	font-weight: 600;
	color: #999;
	margin-top: 10px;
}
.comment-container .comment-content {
	margin-top: 20px;
}
.comment-container .comment-content .comment-text {
	font-size: 16px;
	line-height: 22px;
	color: #555;
}
.comment-container .comment-write {
	position: relative;
	background-color: #fff;
	top: -1px;
	padding-top: 40px;
}
.comment-container .comment-write .comment-column.contents {
	padding-top: 0;
}
.comment-container .comment-write .comment-author {
	padding-top: 10px;
	padding-bottom: 10px;
}
.comment-container .comment-input {
}
.comment-container .comment-input-textarea {
	height: 80px;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 30px;
	color: #555;
	resize: vertical;
	max-height: 200px;
	min-height: 80px;
}
.comment-container .comment-input-actions {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 10px;
}
.comment-container .comment-input-byte {
}
.comment-container .comment-input-byte .byte {
	font-size: 13px;
	letter-spacing: 0;
	color: #555;
}
